<template>
    <v-dialog v-model="dialog" max-width="1200">
        <v-card>
            <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="pa-0">
                    <v-col cols="12" md="4" sm="4">
                        <v-select v-model="filter.sex" :items="sexItems" label="성별" hide-details persistent-placeholder></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-select v-model="filter.startAge" :items="ageItems" label="시작 나이" hide-details persistent-placeholder></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-select v-model="filter.endAge" :items="ageItems" label="끝 나이" hide-details persistent-placeholder></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
            </v-card-actions>
            <v-divider />
            <v-card-title class="tit tit--xs font-weight-bold">{{ survey?.subject }} 통계</v-card-title>
            <!-- <v-chart :data="testStatistics.count" :x-axis-data="testStatistics.xAxisData"/> -->
            <v-data-table :headers="statisticsHeaders" :items="questionArrayWithDepth" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:[`item.question.children`]="{item}">
                    <div v-for="children, index in item.question.children" :key="index">
                        {{ children.label }}
                    </div>
                </template>
                <template v-slot:[`item.count`]="{item}">
                    <div v-for="children, index in item.question.children" :key="index">
                        {{ children.count }}
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import VChart from "@/components/console/statistic/v-chart.vue";

export default {
    components: {
        VChart
    },
    data() {
        return {
            filter: {
                sex: null,
                startAge: null,
                endAge: null,
                _key: null
            },
            dialog: false,
            survey: null,
            statistics: null,
            sexItems: [
                { text: ":: 전체 ::", value: null },
                { text: "여성", value: "여성" },
                { text: "남성", value: "남성" },
            ],
            questionArrayWithDepth: [],

            statisticsHeaders: [
                { text: "뎁스", align: "center", value: "depth", width: 40 },
                { text: "질문", align: "center", value: "question.label", width: 200 },
                { text: "답변", align: "center", value: "question.children", width: 100 },
                { text: "통계", align: "center", value: "count", width: 100 },
            ]
        };
    },
    methods: {
        async init() {
            await this.search()
        },
        async search() {
            this.questionArrayWithDepth = [];
            await this.extractQuestionsWithDepth(this.survey.node)
        },
        async open(survey) {
            this.survey = survey;
            this.extractQuestionsWithDepth(survey.node)
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },

        async extractQuestionsWithDepth(data, depth = 1) {
            if (data.type === "question") {
                if (data.children.length !== 0) {
                    for (let i = 0; i < data.children.length; i++) {
                        this.filter._key = data.children[i]._key;
                        let { count } = await api.console.surveys.statistics({
                            _id: this.survey._id,
                            params: {
                                ...this.filter
                            }
                        });
                        data.children[i].count = count;
                    }
                }
                this.questionArrayWithDepth.push({ question: data, depth });
            }

            // 자식 항목이 있는 경우 재귀적으로 호출
            if (data.children && data.children.length > 0) {
                data.children.forEach(child => {
                this.extractQuestionsWithDepth(child, data.type === "question" ? depth + 1 : depth);
                });
            }
        }
    },
    computed: {
        ageItems() {
            let result = [{ text: "전체", value: null }]
            for(let i = 1; i <= 100; i++) {
                result.push({ text: `${i} 세`, value: i })
            }
            return result
        },
        testStatistics() {
            let statistics = this?.statistics
            let xAxisData = statistics?.map(statistic => `${statistic?.totalScore} 점`) || [];
            let count = statistics?.map(statistic => statistic?.count) || [];
            return {
                xAxisData,
                count
            }
        }
    },
    watch: {
        dialog() {
            if(!this.dialog) {
                this.filter = {
                    sex: null,
                    startAge: null,
                    endAge: null
                }
            }
        }
    }
};
</script>
