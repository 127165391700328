var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_c('span', [_vm._v("인증 상세")])]), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.codeItems,
      "label": "인증유형",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.levelup.code,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "code", $$v);
      },
      expression: "levelup.code"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.categoryItems,
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.levelup.category,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "category", $$v);
      },
      expression: "levelup.category"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "회원아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.levelup.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.levelup.user, "username", $$v);
      },
      expression: "levelup.user.username"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "회원닉네임",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.levelup.user.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.levelup.user, "nickname", $$v);
      },
      expression: "levelup.user.nickname"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.statusItems,
      "label": "승인상태",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.levelup.status,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "status", $$v);
      },
      expression: "levelup.status"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "내용",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.levelup.content,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "content", $$v);
      },
      expression: "levelup.content"
    }
  })], 1)], 1)], 1), _c('v-card-actions')], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("인증 파일")]), _c('v-card-text', [_c('v-col', [_c('v-btn', {
    attrs: {
      "href": _vm.levelup.file,
      "download": "",
      "color": "primary"
    }
  }, [_vm._v("인증 파일 다운로드")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mx-1 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }