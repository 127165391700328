var render = function render(){
  var _vm$question$answers;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" 중독테스트 질문 및 선택지 설정 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', [_vm._v("질문 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "질문",
      "placeholder": "질문",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.question,
      callback: function ($$v) {
        _vm.$set(_vm.question, "question", $$v);
      },
      expression: "question.question"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_vm._v(" 이미지 에디터 "), _vm.dialog ? _c('naver-smarteditor', {
    staticClass: "mt-2",
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', [_vm._v("선택지 정보")]), _c('v-divider'), _c('v-card-text', [_vm._l(_vm.question.answers, function (item, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "mr-3 mt-3"
    }, [_c('v-text-field', {
      attrs: {
        "label": `선택지${index + 1}`,
        "placeholder": `선택지${index + 1}`,
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: item.answer,
        callback: function ($$v) {
          _vm.$set(item, "answer", $$v);
        },
        expression: "item.answer"
      }
    })], 1), _c('v-col', {
      staticClass: "mr-3 mt-3"
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.scoreGroups[0].group,
        "placeholder": _vm.scoreGroups[0].group,
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: item.score1,
        callback: function ($$v) {
          _vm.$set(item, "score1", $$v);
        },
        expression: "item.score1"
      }
    })], 1), _c('v-col', {
      staticClass: "mr-3 mt-3"
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.scoreGroups[1].group,
        "placeholder": _vm.scoreGroups[1].group,
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: item.score2,
        callback: function ($$v) {
          _vm.$set(item, "score2", $$v);
        },
        expression: "item.score2"
      }
    })], 1), _c('v-col', {
      staticClass: "mr-3 mt-3"
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.scoreGroups[2].group,
        "placeholder": _vm.scoreGroups[2].group,
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: item.score3,
        callback: function ($$v) {
          _vm.$set(item, "score3", $$v);
        },
        expression: "item.score3"
      }
    })], 1)], 1);
  }), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-spacer'), ((_vm$question$answers = _vm.question.answers) === null || _vm$question$answers === void 0 ? void 0 : _vm$question$answers.length) !== 1 ? _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.removeAnswer
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addAnswer
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 2)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("확인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }