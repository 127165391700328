var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chart', {
    staticStyle: {
      "height": "200px",
      "width": "100%"
    },
    attrs: {
      "options": _vm.options,
      "autoresize": ""
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }