var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("묻고 답하기")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSearch,
      expression: "showSearch"
    }],
    staticClass: "elevation-1 mx-auto"
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-menu', {
    ref: "createdAts0",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.filter.createdAts[0],
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.filter.createdAts, 0, $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.filter.createdAts, 0, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "등록일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.filter.createdAts[0],
            callback: function ($$v) {
              _vm.$set(_vm.filter.createdAts, 0, $$v);
            },
            expression: "filter.createdAts[0]"
          }
        }, on))];
      }
    }])
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.createdAts0.save(_vm.filter.createdAts[0]);
      }
    },
    model: {
      value: _vm.filter.createdAts[0],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAts, 0, $$v);
      },
      expression: "filter.createdAts[0]"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-menu', {
    ref: "createdAts1",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.filter.createdAts[1],
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.filter.createdAts, 1, $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.filter.createdAts, 1, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "등록일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.filter.createdAts[1],
            callback: function ($$v) {
              _vm.$set(_vm.filter.createdAts, 1, $$v);
            },
            expression: "filter.createdAts[1]"
          }
        }, on))];
      }
    }])
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.createdAts1.save(_vm.filter.createdAts[1]);
      }
    },
    model: {
      value: _vm.filter.createdAts[1],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAts, 1, $$v);
      },
      expression: "filter.createdAts[1]"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.filterItems,
      "label": "상세조건",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "item-text": "text",
      "item-valuie": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": _vm.filter.searchKey == null,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": "",
      "dense": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.questionsHeaders,
      "items": _vm.questions,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.product",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.product ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.product.thumb ? _c('v-img', {
          attrs: {
            "src": item.product.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v("삭제된 상품")])])], 1)];
      }
    }, {
      key: "item.user",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.user && item.user.companyName ? _c('div', [_vm._v(_vm._s(item.user.companyName.ko))]) : _vm._e()];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: "item.reply.content",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.reply.content ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.questionDialog,
      callback: function ($$v) {
        _vm.questionDialog = $$v;
      },
      expression: "questionDialog"
    }
  }, [_vm.questionDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의")])]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  })], 1), _c('v-card-text', [_c('div', {
    staticClass: "caption mt-4 mb-1"
  }, [_vm._v("답변")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "전문가 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.reply.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.reply, "name", $$v);
      },
      expression: "editItem.reply.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.editItem.reply.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.editItem.reply.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "전문가 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.editItem.reply.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.reply, "thumb", $$v);
      },
      expression: "editItem.reply.thumb"
    }
  })], 1)], 1), _c('div', {
    staticClass: "caption mt-4 mb-1"
  }, [_vm._v("답변 내용")]), _c('naver-smarteditor', {
    model: {
      value: _vm.editItem.reply.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.reply, "content", $$v);
      },
      expression: "editItem.reply.content"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }