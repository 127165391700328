var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "headline text-start mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.survey._id ? "설문조사 상세보기" : "설문조사 등록"))])]), _c('v-spacer')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "설문조사 제목",
      "placeholder": "설문조사 제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.survey.subject,
      callback: function ($$v) {
        _vm.$set(_vm.survey, "subject", $$v);
      },
      expression: "survey.subject"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "name",
      "label": "설문조사 카테고리",
      "placeholder": "설문조사 카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.survey.category,
      callback: function ($$v) {
        _vm.$set(_vm.survey, "category", $$v);
      },
      expression: "survey.category"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "256"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('survey-thumb', {
    attrs: {
      "label": "썸네일"
    },
    on: {
      "remove": _vm.removeImage
    },
    model: {
      value: _vm.survey.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.survey, "thumb", $$v);
      },
      expression: "survey.thumb"
    }
  })], 1)], 1)], 1)], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.zoomIn
    }
  }, [_vm._v("zoomIn")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.zoomOut
    }
  }, [_vm._v("zoomOut")]), _c('vue-tree', {
    ref: "tree",
    staticStyle: {
      "width": "1024px",
      "height": "1024px",
      "border": "1px solid gray"
    },
    attrs: {
      "dataset": _vm.survey.node,
      "config": _vm.treeConfig,
      "collapse-enabled": false
    },
    scopedSlots: _vm._u([{
      key: "node",
      fn: function (_ref) {
        var node = _ref.node;
        return [_c('div', [_c('v-card', {
          attrs: {
            "width": "200",
            "height": "150",
            "color": _vm.nodeColor(node)
          }
        }, [_c('v-col', [!node.entryPoint ? _c('v-icon', {
          on: {
            "click": function ($event) {
              return _vm.removeNode(node);
            }
          }
        }, [_vm._v("mdi-close")]) : _vm._e()], 1), _c('v-col', {
          on: {
            "click": function ($event) {
              return _vm.$refs.surveyContentDialog.open(node);
            }
          }
        }, [_vm._v(" " + _vm._s(node.label))]), _c('v-col', [_c('v-row', [node.type === 'answer' && node.children.length < 1 && node.label ? _c('v-btn', {
          attrs: {
            "small": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.addNode(node, _vm.resultNode);
            }
          }
        }, [_vm._v("결과입력")]) : _vm._e(), node.type === 'answer' && node.children.length < 1 && node.label ? _c('v-btn', {
          attrs: {
            "fab": "",
            "color": "yellow"
          },
          on: {
            "click": function ($event) {
              return _vm.addNode(node, _vm.questionNode);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e(), node.type === 'question' && node.label && node.content ? _c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.addNode(node, _vm.answerNode);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 1)], 1)], 1)], 1)];
      }
    }])
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.zoomIn
    }
  }, [_vm._v("zoomIn")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.zoomOut
    }
  }, [_vm._v("zoomOut")]), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1), _c('survey-content-dialog', {
    ref: "surveyContentDialog",
    on: {
      "node": _vm.updateNode
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }