<template>
<v-container fluid fill-height>
    <v-layout justify-center>
        <v-responsive max-width="1440px" width="100%">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">상품목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pt-4 pb-0">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <category-select v-model="filter._category" dense hide-details :categories="categories"></category-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null"></v-select>
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(false);"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search();"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4" align="center">
                <v-col cols="auto">
                    검색된 상품: <b class="primary--text">{{ summary.totalCount?.format?.() }}</b>건
                </v-col>
                <v-col cols="auto">
                    <v-btn color="white" class="pink--text px-2 ml-1" @click="$refs.buyerInput.$el.getElementsByTagName('input')[0].click()">
                        <v-file-input accept=".xlsx" ref="buyerInput" class="d-none" @change="file => upload(file, 'buyer')"/>
                        <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>제품 업로드
                    </v-btn>
                    <v-btn color="white" class="green--text px-2 ml-1" @click="download('/assets/excel/product-example.xlsx')">
                        <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>제품 양식 다운로드
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="auto">
                            <v-btn color="white" class="green--text px-2 ml-1" v-bind="attrs" v-on="on">
                                <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                            </v-btn>
                        </v-col>                        
                    </template>
                    <v-card :loading="isDownload">
                        <v-card-title class="subtitle-1">
                            <b>엑셀다운로드</b>
                            <span class="caption primary--text"><div style='color:#E61A22'>* 주의 *</div>* 여러 개의 엑셀파일이 다운로드 됩니다. 엑셀 병합을 통해 한 개의 파일로 확인해주세요.</span>
                            <span class="caption primary--text">* 엑셀파일이 다운로드되는데 시간이 오래 소요됩니다. 다운로드가 완료될때까지 기달려주세요.</span> 
                        </v-card-title>
                        <v-divider/>
                        <v-card-text>
                            <v-layout v-for="n in excelCount" :key="n" class="mt-2" align-center>
                                    <v-flex grow v-if="n === excelCount">{{ (n - 1) * excelLimit + 1 }}개 ~ {{ summary.totalCount }}개</v-flex>
                                    <v-spacer v-if="n === excelCount"/>
                                    <v-flex grow v-if="n !== excelCount">{{ (n - 1) * excelLimit + 1 }}개 ~ {{ n * excelLimit }}개</v-flex>
                                    <v-spacer v-if="n !== excelCount"/>
                                    <v-flex shrink >
                                        <v-btn  color="white" class="green--text px-2 ml-4" @click="excel( (n-1) * excelLimit, excelLimit)" :disabled="isDownload">
                                            <v-icon  class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                                        </v-btn>       
                                    </v-flex>                                    
                            </v-layout>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text color="grey" :disabled="isDownload" @click="dialog = false">닫기</v-btn>
                        </v-card-actions>                                          
                    </v-card>
                </v-dialog>
            </v-row>
            <v-data-table :headers="productsHeaders" :items="products" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.goods="{item}">
                    <v-row align="center">
                        <v-col md="auto">
                            <v-img v-if="item.thumb" :src="item.thumb" max-width="56" max-height="56"></v-img>
                            <v-responsive v-else width="56" height="56">
                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                    <v-icon>mdi-image</v-icon>
                                </v-row>
                            </v-responsive>
                        </v-col>
                        <v-col>
                            <div class="text-start">{{ item.name.ko }}</div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.salePrice="{item}">
                    <span v-if="item.salePrice">{{ item.salePrice.format() }} KRW</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.salesAmount="{item}">
                    <span v-if="item.salesAmount">{{ item.salesAmount.format() }} KRW</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.exportsAmount="{item}">
                    <span v-if="item.exportsAmount">$ {{ item.exportsAmount.format() }}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.seller.companyName="{item}">
                    <span>{{ item.seller.companyName.ko }}</span>
                </template>
                <template v-slot:item.awardAt="{item}">
                    {{ $dayjs(item.awardAt).format("YYYY-MM-DD") }}
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <product-uploader ref="uploader" @save="search"></product-uploader>

        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import download from "downloadjs";
import { mdiMicrosoftExcel } from "@mdi/js";
import CategorySelect from '@/components/console/shop/categories/category-select.vue';
import ProductUploader from "@/components/console/shop/products/product-uploader.vue";

export default {
    components: {
        CategorySelect,
        ProductUploader
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            dialog: false,
            isDownload: false,
            mdiMicrosoftExcel,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                _category: this.$route.query._category || null,
                name: this.$route.query.name || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제품명", value: "name.ko" },
                { text: "기업명", value: "seller.companyName.ko" },
            ],

            categories: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            excelCount: 0,
            excelLimit: 5000,
            limit: 10,

            summary: { totalCount: 0 },
            products: [],
            excelHeaders: [
            {
                text: 'skip',
                align: 'center',
                width: 250,
                value: 'goods'
            },   
            {
                text: 'limit',
                align: 'center',
                width: 250,
                value: 'goods'
            },  
            {
                text: '다운로드',
                align: 'center',
                width: 250,
                value: 'goods'
            },              
            ],
            productsHeaders: [
            {
                text: '제품',
                align: 'center',
                width: 250,
                value: 'goods'
            },
            {
                text: '기업명',
                align: 'center',
                width: 140,
                value: 'seller.companyName'
            },
            {
                text: '판매가',
                align: 'center',
                width: 80,
                value: 'salePrice'
            },
            {
                text: '매출액(최근1년)',
                align: 'center',
                width: 100,
                value: 'salesAmount'
            },
            {
                text: '수출액(최근1년)',
                align: 'center',
                width: 100,
                value: 'exportsAmount'
            },
            {
                text: '인증구분',
                align: 'center',
                width: 100,
                value: 'awardKind'
            },
            {
                text: '인증서번호',
                align: 'center',
                width: 80,
                value: 'awardNumber'
            },
            {
                text: '선정일',
                align: 'center',
                width: 80,
                value: 'awardAt'
            },
            {
                text: 'Actions',
                width: 100,
                align: 'center',
                value: 'actions'
            }],

            newlyItems: [
                { text: "전체", value: null },
                { text: "신상품", value: true },
                { text: "일반", value: false }
            ],

            bestItems: [
                { text: "전체", value: null },
                { text: "적용중", value: true },
                { text: "미적용 ", value: false }
            ],

            specialItems: [
                { text: "전체", value: null },
                { text: "특가중", value: true },
                { text: "비특가 ", value: false }
            ],

            displayItems: [
                { text: "전체", value: null },
                { text: "전시중", value: true },
                { text: "미전시 ", value: false }
            ],

            soldoutItems: [
                { text: "전체", value: null },
                { text: "품절표시", value: true },
                { text: "미품절 ", value: false }
            ],

            saleItems: [
                { text: "전체", value: null },
                { text: "판매중", value: true },
                { text: "판매불가 ", value: false }
            ]
        };
    },
    methods: {
        download,
        async init(){
            var { categories } = await api.console.shop.categories.gets({ params: { depth: 1 }});
            this.categories = categories;

            if(this.filter._category){
                var { category } = await api.console.shop.categories.get({ _id: this.filter._category });
                this.category = category;
            }

            this.search();
        },
        async search(routable = false){
            if(this.routable){
                this.$router.push({
                    query: Object.assignDefined({}, this.filter, { page: this.page })
                });
            }

            var { summary, products } = await api.console.shop.products.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.products = products;
            this.summary = summary;
            this.excelCount = Math.ceil(summary.totalCount / this.excelLimit)
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`${this.$route.path}/create`);
        },
        copy(product){
            this.$router.push(`${this.$route.path}/copy?_product=${product._id}`);
        },
        edit(product){
            this.$router.push(`${this.$route.path}/${product._id}`);
        },

        upload(file){
            if(!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: 'binary', cellDates: true });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });
                console.log(workSheets[0]);
                this.$refs.uploader.excelSheet = workSheets[0]; // 첫번째 시트
                this.$refs.uploader.open();
            };
            reader.readAsBinaryString(file);
        },

        async excel(skip, limit){
            this.isDownload = true;

            var { products } = await api.console.shop.products.gets({
                headers: {
                    skip,
                    limit
                },
                params: this.filter
            });

            var rows = [];
            products.forEach((product) => {
                let a = 0
                let category = product._category ? this.categories.find(({ _id, children }) => _id == product._category || children.find(({ _id }) => _id == product._category) ) : null;
                let subCategory = product._category ? category.children.find(({ _id }) => _id == product._category) : null;
                // console.log('========',product.seller.companyName.ko)
                rows.push({
                    "상품코드": product._id,
                    "셀러": product?.seller?.companyName?.ko,
                    "사업자번호": product?.seller?.businessNumber,
                    "상품명": product.name.ko,
                    "상품명-영문": product.name.en,
                    "상품명-중문": product.name.cn,
                    "썸네일 URL": product.thumb,
                    "상품사진2 URL": product.images[0],
                    "카테고리-대분류": category?.name?.ko,
                    "카테고리-중분류": subCategory?.name?.ko,
                    "주요특징": product.summary.ko,
                    "주요특징-영문": product.summary.en,
                    "주요특징-중문": product.summary.cn,
                    "제조국": product.country.ko,
                    "제조국-영문": product.country.en,
                    "제조국-중문": product.country.cn,
                    "컬러": product.color.ko,
                    "컬러-영문": product.color.en,
                    "컬러-중문": product.color.cn,
                    "사이즈": product.size.ko,
                    "사이즈(영문)": product.size.en,
                    "사이즈(중문)": product.size.cn,
                    "전 성분": product.elements.ko,
                    "전 성분-영문": product.elements.en,
                    "전 성분-중문": product.elements.cn,
                    "주요판로처(온라인)": product.onlineDistributor.ko,
                    "주요판로처(온라인)-영문": product.onlineDistributor.en,
                    "주요판로처(온라인)-중문": product.onlineDistributor.cn,
                    "주요판로처(오프라인)": product.offlineDistributor.ko,
                    "주요판로처(오프라인)-영문": product.offlineDistributor.en,
                    "주요판로처(오프라인)-중문": product.offlineDistributor.cn,
                    "제조방법": product.manufacture,
                    "제조업체": product.company,
                    "소비자가": product.price,
                    "판매가": product.salePrice,
                    "국내매출액(최근1년)": product.salesAmount,
                    "수출액(최근1년)": product.exportsAmount,
                    "판매사이트": product.site,
                    "홈페이지": product.homepage,
                    "SMC홍보마당URL": product.promotion,
                    "인증구분": product.awardKind,
                    "인증번호": product.awardNumber,
                    "선정일": product.awardAt,
                    "출시일자": product.publishingAt,
                });
            });

            var workbook = new XLSX.utils.book_new();
            var worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "new");
            XLSX.writeFile(workbook, "상품목록.xlsx");

            this.isDownload = false;
        },

        async save(product){
            await api.console.shop.products.put(product);
            await this.search();
        },

        async remove(product){
            if(confirm("해당 상품을 삭제하시겠습니까?")){
                await api.console.shop.products.delete(product);
                await this.search();

                alert("삭제되었습니다");
            }
        },
    }
}
</script>
