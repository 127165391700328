<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ survey._id ? "설문조사 상세보기" : "설문조사 등록" }}</span>
                </v-col>
                <v-spacer />
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">기본정보</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="survey.subject" label="설문조사 제목" placeholder="설문조사 제목" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select v-model="survey.category" :items="categories" item-text="name" item-value="name" label="설문조사 카테고리" placeholder="설문조사 카테고리" persistent-placeholder hide-details/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-card height="256">
                        <v-card-title class="subtitle-1 font-weight-bold">썸네일</v-card-title>
                        <v-card-text>
                            <survey-thumb v-model="survey.thumb" label="썸네일" @remove="removeImage"></survey-thumb>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-btn color="primary" @click="zoomIn">zoomIn</v-btn>
            <v-btn color="primary" @click="zoomOut">zoomOut</v-btn>
            <vue-tree ref="tree" style="width: 1024px; height: 1024px; border: 1px solid gray;" :dataset="survey.node" :config="treeConfig" :collapse-enabled="false">
                <template v-slot:node="{ node }">
                    <div>
                        <v-card width="200" height="150" :color="nodeColor(node)">
                            <v-col>
                                <v-icon v-if="!node.entryPoint" @click="removeNode(node)">mdi-close</v-icon>
                            </v-col>
                            <v-col @click="$refs.surveyContentDialog.open(node)"> {{ node.label }}</v-col>
                            <v-col>
                                <v-row>
                                    <v-btn v-if="node.type === 'answer' && node.children.length < 1 && node.label" small color="blue" @click="addNode(node, resultNode)">결과입력</v-btn>
                                    <v-btn v-if="node.type === 'answer' && node.children.length < 1 && node.label" fab color="yellow" @click="addNode(node, questionNode)"><v-icon>mdi-plus</v-icon></v-btn>
                                    <v-btn v-if="node.type === 'question' && node.label && node.content" fab color="primary" @click="addNode(node, answerNode)"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-card>
                    </div>
                </template>
            </vue-tree>
            <v-btn color="primary" @click="zoomIn">zoomIn</v-btn>
            <v-btn color="primary" @click="zoomOut">zoomOut</v-btn>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
        <survey-content-dialog ref="surveyContentDialog" @node="updateNode"/>
    </v-layout>
</template>

<script>
import api from '@/api';
import { v4 as uuid } from "uuid"
import surveyContentDialog from '@/components/console/survey/survey-content-dialog.vue';
import surveyThumb from '@/components/console/survey/survey-thumb.vue';

export default {
    components: {
        surveyContentDialog,
        surveyThumb
    },
    data() {
        return {
            treeConfig: { nodeWidth: 200, nodeHeight: 150, levelHeight: 250 },
            categories: null,

            survey: {
                _id: this.$route.params._survey || null,
                subject: null, 
                category: null,
                node: {
                    label: null,
                    type: "question", // question, answer, result
                    entryPoint: true,
                    children: [],
                    key: uuid()
                }
            },

            questionNode: {
                label: null,
                type: "question",
                children: [],
                entryPoint: false
            },

            answerNode: {
                label: null,
                type: "answer",
                children: []
            },

            resultNode: {
                label: null,
                type: "result",
            },
        };
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            let { categories } = await api.console.surveys.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 },
            })
            this.categories = categories;

            if (this.$route.params._survey) {
                let { survey } = await api.console.surveys.get(this.survey)
                if (survey.thumb) survey.thumb = await api.getResource(survey.thumb);
                this.survey = survey
            }
        },
        async save() {
            let { survey } = this.$route.params._survey ? await api.console.surveys.put({...this.survey, thumb: null}) : await api.console.surveys.post({...this.survey, thumb: null})

                            ////////////////////////////////////////////////////////////////
                // 4. 썸네일 저장
                ////////////////////////////////////////////////////////////////
                if (this.survey.thumb) await api.console.surveys.thumb.post(survey._id, this.survey.thumb);
            alert("저장되었습니다")

            this.$router.push("/console/survey")
        },
        addNode(node, newNodeStructure) {
            const NODE = {
                ...newNodeStructure,
                key: uuid(),
            }
            node.children.push(NODE);

            this.updateNodeInRichMediaData(this.survey.node, node.key, newNode => {
                newNode.children = node.children;
            });
        },
        updateNodeInRichMediaData(node, key, updateFn) {
            if (node.key === key) {
                updateFn(node);
                return true;
            }

            if (node.children) {
                for (const child of node.children) {
                    if (this.updateNodeInRichMediaData(child, key, updateFn)) {
                        return true; // Return after updating the specific node
                    }
                }
            }

            return false;
        },

        removeNode(node) {
            this.removeNodeByKey(this.survey.node, node.key);
        },

        removeNodeByKey(rootNode, keyToRemove) {
            if (rootNode.children) {
                // Find the index of the node with the specified key
                const index = rootNode.children.findIndex(child => child.key === keyToRemove);

                if (index !== -1) {
                    rootNode.children.splice(index, 1); // Remove the node from the children array
                    return;
                }

                // Recursively search in children nodes
                for (const child of rootNode.children) {
                    this.removeNodeByKey(child, keyToRemove);
                }
            }
        },
        updateNode(node) {
            this.updateNodeInRichMediaData(this.survey.node, node.key, newNode => {
                newNode.label = node.label;
                newNode.content = node.content;
                newNode.hashTag = node.hashTag;
            });
        },
        zoomIn() {
            this.$refs.tree.zoomIn();
        },
        zoomOut() {
            this.$refs.tree.zoomOut();
        },

        nodeColor(node) {
            if (node.type === "question") return "blue"
            if (node.type === "result") return "red"
            return "white"
        }
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
