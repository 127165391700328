<template>
    <console-content max-width="1024">

        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="tit tit--lg">
                <span>댓글 설정</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-4">
            <v-card-title class="tit tit--xs">설정 정보</v-card-title>
            <v-card-text>
                <v-layout class="mx-n2">
                    <v-flex class="px-2">
                        <v-text-field v-model="config.recommentCount" label="대댓글 갯수" persistent-placeholder hide-details />
                    </v-flex>
                    <v-flex class="px-2">
                        <v-text-field v-model="config.likeDislikeCount " label="추천 + 반대" persistent-placeholder hide-details />
                    </v-flex>
                    <v-flex class="px-2">
                        <v-text-field v-model="config.commentCount" label="댓글 수" persistent-placeholder hide-details />
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            config: {
                _id: null,

                recommentCount: null,
                likeDislikeCount: null,
                commentCount: null
            }
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            let { config } = await api.console.boards.comments.configs.get(this.config);
            this.config = config;
            console.log(this.config)
        },
        async save(){
            await api.console.boards.comments.configs.put(this.config)
            alert("저장되었습니다");
        }
    }
}
</script>