var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("관리자 상세")]) : _c('span', [_vm._v("관리자 등록")])]), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', [_c('v-password-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(이름)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('v-col')], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(연락처)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(이메일)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "우편번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "postcode", $$v);
      },
      expression: "user.postcode"
    }
  })], 1), _c('v-col')], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "기본주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address2", $$v);
      },
      expression: "user.address2"
    }
  })], 1)], 1)], 1), _c('v-card-actions')], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mx-1 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }