var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', {
    staticClass: "tit tit--xs font-weight-bold",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("카테고리 통합")]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('div', [_vm._v("통합할 카테고리")]), _c('category-select', {
    attrs: {
      "categories": _vm.categories,
      "return-object": "",
      "allowDepth": [1, 2]
    },
    model: {
      value: _vm.parent1,
      callback: function ($$v) {
        _vm.parent1 = $$v;
      },
      expression: "parent1"
    }
  })], 1), _c('v-card-text', {
    staticClass: "pb-0 mt-1"
  }, [_c('div', [_vm._v("통합될 카테고리")]), _c('category-select', {
    attrs: {
      "categories": _vm.categories,
      "return-object": "",
      "allowDepth": [1, 2]
    },
    model: {
      value: _vm.parent2,
      callback: function ($$v) {
        _vm.parent2 = $$v;
      },
      expression: "parent2"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.integrated
    }
  }, [_vm._v("통합")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }