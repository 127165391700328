var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" 중독테스트 결과 설정 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', [_vm._v("점수 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "점수"
    },
    model: {
      value: _vm.result.score,
      callback: function ($$v) {
        _vm.$set(_vm.result, "score", $$v);
      },
      expression: "result.score"
    }
  })], 1), _c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "테스터 상태"
    },
    model: {
      value: _vm.result.status,
      callback: function ($$v) {
        _vm.$set(_vm.result, "status", $$v);
      },
      expression: "result.status"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "조치사항1"
    },
    model: {
      value: _vm.result.need1,
      callback: function ($$v) {
        _vm.$set(_vm.result, "need1", $$v);
      },
      expression: "result.need1"
    }
  })], 1), _c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "조치사항2"
    },
    model: {
      value: _vm.result.need2,
      callback: function ($$v) {
        _vm.$set(_vm.result, "need2", $$v);
      },
      expression: "result.need2"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.hashtags,
      "label": "리커버스 추천 해시태그",
      "item-text": "name",
      "item-value": "code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.result.hashTag,
      callback: function ($$v) {
        _vm.$set(_vm.result, "hashTag", $$v);
      },
      expression: "result.hashTag"
    }
  })], 1), _c('v-col', {
    staticClass: "mr-3"
  })], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_vm._v(" 이미지 에디터 "), _vm.dialog ? _c('naver-smarteditor', {
    staticClass: "mt-2",
    model: {
      value: _vm.result.content,
      callback: function ($$v) {
        _vm.$set(_vm.result, "content", $$v);
      },
      expression: "result.content"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', [_vm._v("추천콘텐츠 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "콘텐츠 _id",
      "placeholder": "추천할 콘텐츠 _id을 입력해주세요",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.result._recommendContent,
      callback: function ($$v) {
        _vm.$set(_vm.result, "_recommendContent", $$v);
      },
      expression: "result._recommendContent"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("확인")])], 1)], 1), _c('addiction-recommed-view', {
    ref: "recommendview",
    on: {
      "select": function (value) {
        return _vm.select(value);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }