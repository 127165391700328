<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1440px" width="100%">
                <v-layout class="mx-n1 mt-4">
                    <v-flex xs6 class="px-1">
                        <v-card>
                            <v-card-actions class="font-size-14 pa-4">
                                <span>오늘 가입한 회원</span>
                                <v-spacer/>
                                <span><b>{{ summary?.todayRegisteredCount }}</b>명</span>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                    <v-flex xs6 class="px-1">
                        <v-card>
                            <v-card-actions class="font-size-14 pa-4">
                                <span>전체 회원수</span>
                                <v-spacer/>
                                <span><b >{{ summary?.totalCount }}</b>명</span>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>

                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">월간 가입자수</v-card-title>
                    <v-chart :data="monthlyStatistics.count" :x-axis-data="monthlyStatistics.xAxisData"/>
                </v-card>
                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">주간 가입자수</v-card-title>
                    <v-chart :data="weekStatistics.count" :x-axis-data="weekStatistics.xAxisData"/>
                </v-card>
                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">일간 가입자수</v-card-title>
                    <v-chart :data="dailyStatistics.count" :x-axis-data="dailyStatistics.xAxisData"/>
                </v-card>

                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">월간 방문자수</v-card-title>
                    <v-chart  :data="historyMonthlyStatistics.count" :x-axis-data="historyMonthlyStatistics.xAxisData"/>
                </v-card>
                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">주간 방문자수</v-card-title>
                    <v-chart :data="historyWeekStatistics.count" :x-axis-data="historyWeekStatistics.xAxisData"/>
                </v-card>
                <v-card class="mt-4 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">일간 방문자수</v-card-title>
                    <v-chart :data="historyDailyStatistics.count" :x-axis-data="historyDailyStatistics.xAxisData"/>
                </v-card>

            </v-responsive>
        </v-layout>
    </v-container>
</template>
<script>
import api from "@/api";
import VChart from "@/components/console/statistic/v-chart.vue";
export default {
    components: {
        VChart
    },
    data(){
        return {
            statistic: null,
            summary: null
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            let statistic = await api.console.users.statistics.get()
            this.statistic = statistic;
            this.summary = statistic.summary
            console.log(statistic);
        },
    },

    computed: {
        dailyStatistics(){
            let statistics = this.statistic?.dateStatistic.reduce((statistics, statistic) => {
                return [...statistics, ...statistic.month.reduce((statistics, statistic) => {
                    return [...statistics, ...statistic.day]
                },[])]
            }, []) || [];
            let xAxisData = statistics.map(statistic => statistic.ymd) || [];
            let count = statistics.map(statistic => statistic.count) || [];
            return {
                xAxisData,
                count
            }
        },
        weekStatistics() {
            let statistics = this.statistic?.weekStatistic
            let xAxisData = statistics.map(statistic => statistic.week) || [];
            let count = statistics.map(statistic => statistic.count) || [];
            return {
                xAxisData,
                count
            }
        },
        monthlyStatistics(){
            let statistics = this.statistic?.dateStatistic.reduce((statistics, statistic) => {
                return [...statistics, ...statistic.month]
            }, []) || [];
            let xAxisData = statistics.map(statistic => statistic.ym) || [];
            let count = statistics.map(statistic => statistic.count) || [];

            return {
                xAxisData,
                count
            }
        },
        historyDailyStatistics(){
            let statistics = this.statistic?.historyDateStatistic.reduce((statistics, statistic) => {
                return [...statistics, ...statistic.month.reduce((statistics, statistic) => {
                    return [...statistics, ...statistic.day]
                },[])]
            }, []) || [];
            let xAxisData = statistics.map(statistic => statistic.ymd) || [];
            let count = statistics.map(statistic => statistic.count) || [];
            return {
                xAxisData,
                count
            }
        },
        historyWeekStatistics() {
            let statistics = this.statistic?.historyWeekStatistic
            let xAxisData = statistics.map(statistic => statistic.week) || [];
            let count = statistics.map(statistic => statistic.count) || [];
            return {
                xAxisData,
                count
            }
        },
        historyMonthlyStatistics(){
            let statistics = this.statistic?.historyDateStatistic.reduce((statistics, statistic) => {
                return [...statistics, ...statistic.month]
            }, []) || [];
            let xAxisData = statistics.map(statistic => statistic.ym) || [];
            let count = statistics.map(statistic => statistic.count) || [];

            return {
                xAxisData,
                count
            }
        },
    }
}
</script>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
    width: 100%;
    height: 100%;
}
</style>
