<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">키워드 통계 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col v-if="['member'].includes(code)" cols="6" md="3" class="py-0">
                                <v-select v-model="filter.sex" :items="sexKeys" label="성별" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col v-if="['member'].includes(code)" cols="6" md="3" class="py-0">
                                <v-select v-model="filter.age" :items="ageKeys" label="나이" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>  
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search(false)"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="keywords" :headers="keywordsHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.rank`]="{ item }">
                    {{ limit + (+keywords.indexOf(item) + 1) - 10 }}
                </template>
            </v-data-table>
            <!-- <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination> -->

            <!-- <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField
    },
    data() {
        return {
            showSearch: true,
            filter: {
                code: this.$route.query.code,
                sex: this.$route.query.sex || null,
                age: this.$route.query.age || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            }, 
            keywords: [],
            limit: 10,

            keywordsHeaders: [
                { text: "순위", align: "center", width: 40, value: "rank" },
                { text: "검색키워드", align: "center", width: 200, value: "keyword" },
                { text: "조회수", align: "center", width: 100, value: "count" },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "검색키워드", value: "keyword" },
            ],
            sexKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "여성", value: "여성" },
                { text: "남성", value: "남성" },
            ],
            ageKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "10대", value: "10" },
                { text: "20대", value: "20" },
                { text: "30대", value: "30" },
                { text: "40대", value: "40" },
                { text: "50대", value: "50" },
                { text: "60대", value: "60" },
                { text: "70대", value: "70" },
                { text: "80대", value: "80" },
                { text: "90대", value: "90" },
                { text: "100대", value: "100" },
            ]
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter }
                });
                return;
            }
        
            var { summary, keywords } = await api.console.statistics.gets({
                headers: {
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.keywords = keywords;
        },
    },
    computed: {
        code() {
            return this.$route.query.code;
        }
    },
    watch: {
        async "$route.query.code"() {
            console.log(this.$route.query.code);
            this.filter.code = this.$route.query.code
            await this.init()
        }
    }
};
</script>
