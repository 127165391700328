var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("회원 상세")]) : _c('span', [_vm._v("회원 등록")])]), _vm.user.levelCode === 'expert' ? _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs"
  }, [_vm._v("리커버 정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.recover.name,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "name", $$v);
      },
      expression: "user.recover.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "직업",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.recover.job,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "job", $$v);
      },
      expression: "user.recover.job"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.jobs,
      "label": "직업 카테고리",
      "item-text": "name",
      "item-value": "code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.recover.category,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "category", $$v);
      },
      expression: "user.recover.category"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "썸네일",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.file.$refs.input.click();
      }
    },
    model: {
      value: _vm.user.recover.thumb.name,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover.thumb, "name", $$v);
      },
      expression: "user.recover.thumb.name"
    }
  }), _c('v-file-input', {
    ref: "file",
    staticClass: "d-none",
    attrs: {
      "accept": ".jpg,.png"
    },
    on: {
      "change": _vm.upload
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.addictions,
      "label": "리커버 중독유형",
      "item-text": "name",
      "item-value": "code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.recover.addiction,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "addiction", $$v);
      },
      expression: "user.recover.addiction"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.hashtags,
      "label": "리커버스 추천 해시태그",
      "item-text": "name",
      "item-value": "code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.recover.hashTag,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "hashTag", $$v);
      },
      expression: "user.recover.hashTag"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" 소개글 "), _c('naver-smarteditor', {
    staticClass: "mt-1",
    model: {
      value: _vm.user.recover.introduction,
      callback: function ($$v) {
        _vm.$set(_vm.user.recover, "introduction", $$v);
      },
      expression: "user.recover.introduction"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', [_c('v-password-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "기본주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.levelCodes,
      "label": "회원등급",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.levelCode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "levelCode", $$v);
      },
      expression: "user.levelCode"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "생년월일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.bornAt,
      callback: function ($$v) {
        _vm.$set(_vm.user, "bornAt", $$v);
      },
      expression: "user.bornAt"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "성별",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.sex,
      callback: function ($$v) {
        _vm.$set(_vm.user, "sex", $$v);
      },
      expression: "user.sex"
    }
  })], 1), _c('v-col')], 1)], 1), _c('v-card-actions')], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mx-1 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }