<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ banner._id ? "배너 상세보기" : "배너 등록" }}</span>
                </v-col>
                <v-spacer />
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="banner.name" label="제목" placeholder="제목" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="banner.url" label="광고링크" placeholder="광고링크" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="6" class="py-0">
                                    <v-select v-model="banner.code" :items="bannerCodes" label="배너위치" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-select v-model="banner.levelCode" :items="levlelCodes" label="회원노출여부" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="6" class="py-0">
                                    <v-select v-model="banner.onDisplay" :items="display" label="노출여부" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">이미지</v-card-title>
                        <v-card-text>
                            <banner-image v-model="banner.image" label="이미지" @remove="removeImage"></banner-image>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiCloudUpload } from "@mdi/js";
import draggable from "vuedraggable";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

import BannerImage from "@/components/console/banner/banner-image.vue";

export default {
    components: {
        draggable,

        VPreviewInput,
        VFileBtn,

        DaumPostcode,
        NaverSmarteditor,
        VDateField,

        BannerImage,
    },
    data() {
        return {
            mdiCloudUpload,

            images: null,

            banner: {
                _id: null,

                name: null,
                code: null,
                image: null,
                url: null,
                levelCode: null,
                onDisplay: null,
            },

            bannerCodes: [
                { text: ":: 위치를 선택해주세요 ::", value: null },
                { text: "상단", value: "top" },
                { text: "사이드", value: "side" },
                { text: "하단", value: "buttom" },
                {text: "커뮤니티", value: "community" },
            ],
            levlelCodes: [
                { text: ":: 노출할 회원등급을 선택해주세요 ::", value: null },
                { text: "비로그인", value: "no" },
                { text: "일반회원", value: "normal" },
                { text: "유경험자", value: "experience" },
                { text: "전문가", value: "professional" },
            ],
            display: [
                { text: ":: 배너 노출여부를 선택해주세요 ::", value: null },
                { text: "비노출", value: false },
                { text: "노출", value: true },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$route.params._banner) {
                var { banner } = await api.console.banners.get({ _id: this.$route.params._banner });

                if (banner.image) banner.image = await api.getResource(banner.image);

                this.banner = banner;
            }

            // if (!this.banner.slides.length) {
            //     this.banner.slides = [...this.banner.slides, { image: null, url: null, meta: {} }];
            // }
        },

        validate() {
            try {
                if (!this.banner.name) throw new Error("배너 명칭을 입력해주세요");
                if (!this.banner.code) throw new Error("배너 코드를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                let { banner } = this.banner._id ? await api.console.banners.put({ ...this.banner, image: null }) : await api.console.banners.post({ ...this.banner, image: null });

                // for (let index in this.banner.slides) {
                //     if (this.banner.slides[index]) await api.console.banners.images.post(banner._id, index, this.banner.slides[index].image);
                // }

                ////////////////////////////////////////////////////////////////
                // 4. 썸네일 저장
                ////////////////////////////////////////////////////////////////
                if (this.banner.image) await api.console.banners.images.post(banner._id, this.banner.image);

                alert("저장되었습니다");
                this.$router.push(`/console/banners`);
            }
        },

        // upload(files) {
        //     this.banner.slides = [...files.map((file) => ({ image: file, url: null, meta: {} })), ...this.banner.slides];
        // },

        async removeImage() {
            try {
                if (confirm("상품 썸네일을 삭제하시겠습니까?")) {
                    if (this.banner._id) await api.console.banners.images.delete(this.banner._id);
                    this.banner.image = null;
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
