var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-4 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.value ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.value),
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": _vm.label,
      "show-size": "",
      "dense": "",
      "accept": "image/*"
    },
    on: {
      "change": _vm.input
    }
  })], 1), _vm.value ? _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }