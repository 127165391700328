<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">1:1 문의</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mx-auto">
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createdAts0" :close-on-content-click="false" :return-value.sync="filter.createdAts[0]" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.createdAts[0]" label="등록일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createdAts[0]" no-title scrollable @input="$refs.createdAts0.save(filter.createdAts[0])"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createdAts1" :close-on-content-click="false" :return-value.sync="filter.createdAts[1]" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.createdAts[1]" label="등록일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createdAts[1]" no-title scrollable @input="$refs.createdAts1.save(filter.createdAts[1])"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-select v-model="filter.searchKey" :items="filterItems" label="상세조건" persistent-placeholder dense hide-details item-text="text" item-valuie="value"></v-select>
                                </v-col>
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-text-field v-model="filter.searchValue" :disabled="filter.searchKey == null" dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="inquirysHeaders" :items="inquirys" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">

                    <template v-slot:item.no="{ item }">
                        <div>{{ (page - 1) * limit + (+inquirys.indexOf(item) + 1) }}</div>
                    </template>

                    <template v-slot:item.reply.content="{ item }">
                        <div v-if="item.reply.content">답변완료</div>
                        <div v-else>대기중</div>
                    </template>

                    <template v-slot:item.type="{ item }">
                        <div v-if="item.type === 'member'">회원문의</div>
                        <div v-else>비회원문의</div>
                    </template>

                    <template v-slot:item.createdAt="{ item }">
                        <div>{{ $dayjs(item.createdAt).format("YYYY-MM-DD hh:mm:ss") }}</div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item)">mdi-delete</v-icon>
                    </template>

                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>


                <v-dialog v-model="inquiryDialog" max-width="720px">
                    <v-card v-if="inquiryDialog">
                        <v-card-title>
                            <span class="subtitle-1">문의</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                            <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>
                            <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details class="mt-6"></v-textarea>
                            <v-col>
                                <div>문의 파일</div>
                                <v-btn :href="editItem.file" download>문의 파일 다운로드</v-btn>
                            </v-col>
                        </v-card-text>

                        <v-card-text>
                            <div class="caption mt-4 mb-1">답변</div>
                            <div class="caption mt-4 mb-1">답변 내용</div>
                            <naver-smarteditor v-model="editItem.reply.content"></naver-smarteditor>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor
    },
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            filter: {
                createdAts: ['', ''],
                searchKey: '',
                searchValue: ''
            },

            filterItems: [
                { text: "전체", value: '' },
                { text: "회원아이디", value: "user.username" },
            ],

            ///////////////////////////////////////////////
            // 상품문의 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            inquirys: [],
            inquirysHeaders: [
                { text: '.no', align: 'center', value: 'no', width: 50 }, 
                { text: '제목', align: 'center', value: 'subject', width: 200 },
                { text: '작성자', align: 'center', value: 'writer.name', width: 100 }, 
                { text: '답변여부', align: 'center', value: 'reply.content', width: 50 }, 
                { text: '회원여부', align: 'center', value: 'type', width: 50 }, 
                { text: '작성일자', align: 'center', value: 'createdAt', width: 100 }, 
                { text: 'Actions', align: 'center', value: 'actions', width: 50 }
            ],

            ///////////////////////////////////////////////////
            //
            ///////////////////////////////////////////////////
            inquiryDialog: false,
            editItem: {
                id: null,
                subject: null,
                keyword: null,

                reply: {
                    content: null,
                    name: null,
                    thumb: null,
                },
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search(){
            try{
                var { summary, inquirys } = await api.console.center.inquirys.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.inquirys = inquirys;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async edit(inquiry){
            this.editItem = Object.assign({}, inquiry);
            this.inquiryDialog = true;
        },

        async remove(inquiry){
            if(confirm(`${inquiry.writer.name}님의 1:1 문의를 삭제하시겠습니까?`)) {
                await api.console.center.inquirys.delete({ _id: inquiry._id })
                alert("1:1 문의가 삭제되었습니다")
                await this.init()
            }
        },

        close(){
            this.inquiryDialog = false;
        },

        async save(){
            try{
                let { question } = await api.console.center.inquirys.put(this.editItem);

                await this.search();
                alert("저장되었습니다");
                this.inquiryDialog = false;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    }
}
</script>
