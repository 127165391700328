<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ addiction._id ? "테스트 상세보기" : "테스트 등록" }}</span>
                </v-col>
                <v-spacer />
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">기본정보</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="addiction.subject" label="테스트 제목" placeholder="테스트 제목" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select v-model="addiction.code" :items="categories" item-text="name" item-value="name" label="카테고리" placeholder="카테고리" persistent-placeholder hide-details></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">테마컬러 설정</v-card-title>
                        <v-card-text>
                            <v-color-picker v-model="addiction.themeColor" dot-size="25" mode="hexa" swatches-max-height="200" hide-mode-switch></v-color-picker>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">
                            <span>인기테스트 설정</span>
                            <v-spacer />
                            <v-switch v-model="addiction.isPopular" hide-details class="mt-0" />
                        </v-card-title>
                    </v-card>
                    <v-card class="mt-3">
                        <v-card-title class="subtitle-1 font-weight-bold">추천콘텐츠 (고정)</v-card-title>
                        <v-card-text>
                            <v-row >
                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="addiction._recommendContent" label="콘텐츠 _id" placeholder="추천할 콘텐츠 _id을 입력해주세요" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-3">
                        <v-card-title class="subtitle-1 font-weight-bold">해쉬태그 설정</v-card-title>
                        <v-card-text>
                            <v-row >
                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="addiction.hashTags" label="해쉬태그" placeholder="ex) 건강, 행복, 감성 = #건강 #행복 #감성" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card height="256">
                        <v-card-title class="subtitle-1 font-weight-bold">썸네일</v-card-title>
                        <v-card-text>
                            <addiction-thumb v-model="addiction.thumb" label="썸네일" @remove="removeImage"></addiction-thumb>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">검사점수 설정</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3" v-for="(scoreGroup, index) in addiction.scoreGroups" :key="index">
                                <v-col cols="4" class="py-0">
                                    <v-text-field v-model="scoreGroup.group" :label="`항목${index + 1}`" placeholder="ex) 빈도수" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="4" class="py-0">
                                    <v-text-field v-model="scoreGroup.maxScore" label="최대점수" placeholder="최대점수" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="4" class="py-0">
                                    <v-text-field v-model="scoreGroup._recommendContent" label="콘텐츠 _id" placeholder="추천할 콘텐츠 _id을 입력해주세요" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title class="subtitle-1 font-weight-bold">동률점수 추천콘텐츠</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col class="py-0">
                                    <v-text-field v-model="addiction._recommendContent2" label="콘텐츠 _id" placeholder="추천할 콘텐츠 _id을 입력해주세요" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">질문 정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col v-for="(item, index) in addiction.questions" :key="index" cols="12" class="py-0">
                                    <v-text-field v-model="item.question" :label="`질문${index + 1}`" :placeholder="`질문${index + 1}`" readonly persistent-placeholder hide-details @click=" openQuestionview(item, addiction.scoreGroups)" />
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-spacer />
                                <v-btn v-if="addiction.questions.length !== 1" color="primary" class="mr-3" @click="removeQuestion">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn color="primary" class="mr-3" @click="addQuestion">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">결과 정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col v-for="(item, index) in addiction.results" :key="index" cols="12" class="py-0">
                                    <v-text-field v-model="item.score" :label="`${item.score} 점`" readonly hide-details @click="$refs.resultview.open(item)" />
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-spacer />
                                <v-btn v-if="addiction.results.length !== 1" color="primary" class="mr-3" @click="removeResult">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn v-if="addiction.results.length !== 21" color="primary" class="mr-3" @click="addResult">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <addiction-question-view ref="questionview"></addiction-question-view>
            <addiction-result-view ref="resultview"></addiction-result-view>
            <addiction-recommed-view ref="recommendview" @select="value => select(value)"></addiction-recommed-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiCloudUpload } from "@mdi/js";
import draggable from "vuedraggable";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

import BannerImage from "@/components/console/banner/banner-image.vue";
import addictionThumb from "@/components/console/addiction/addiction-thumb.vue";
import addictionQuestionView from "@/components/console/addiction/addiction-question-view.vue";
import addictionResultView from "@/components/console/addiction/addiction-result-view.vue";
import addictionRecommedView from "@/components/console/addiction/addiction-recommend-view.vue";

export default {
    components: {
        draggable,

        VPreviewInput,
        VFileBtn,

        DaumPostcode,
        NaverSmarteditor,
        VDateField,

        BannerImage,

        addictionThumb,
        addictionQuestionView,
        addictionResultView,
        addictionRecommedView,
    },
    data() {
        return {
            mdiCloudUpload,

            addiction: {
                _id: null,

                subject: null,
                _recommendContent: null,
                _recommendContent2: null,
                thumb: null,
                code: null,
                themeColor: null,
                type: this.$route.query.type,
                scoreGroups: [
                    { group: null, maxScore: null, _recommendContent: null },
                    { group: null, maxScore: null, _recommendContent: null },
                    { group: null, maxScore: null, _recommendContent: null },
                ],
                questions: [
                    {
                        question: null,
                        content: null,
                        answers: [
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                        ],
                    },
                    {
                        question: null,
                        content: null,
                        answers: [
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                        ],
                    },
                    {
                        question: null,
                        content: null,
                        answers: [
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                        ],
                    },
                    {
                        question: null,
                        content: null,
                        answers: [
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                            { answer: null, score1: null, score2: null, score3: null },
                        ],
                    },
                ],
                results: [
                    { score: 0, status: null, need1: null, need2: null, content: null, _recommendContent: null },
                    { score: 5, status: null, need1: null, need2: null, content: null, _recommendContent: null },
                    { score: 10, status: null, need1: null, need2: null, content: null, _recommendContent: null },
                    { score: 15, status: null, need1: null, need2: null, content: null, _recommendContent: null },
                ],
                isPopular: false,
                hashTags: null,
            },

            categories: null,

            selected: {
                _id: null,
                subject: null,
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$route.params._addiction) {
                let { categories } = await api.console.addictions.categories.gets({
                    params: { dapth: 1 }
                })
                this.categories = categories;
                var { addiction } = await api.console.addictions.get({ _id: this.$route.params._addiction });
                if (addiction.thumb) addiction.thumb = await api.getResource(addiction.thumb);
                this.addiction = addiction;
                if (addiction.recommnedContent) {
                    this.addiction._recommendContent = this.addiction.recommnedContent._id
                    this.selected.subject = this.addiction.recommnedContent.subject
                }
            }
        },

        validate() {
            try {
                if (!this.addiction.subject) throw new Error("테스트 제목을 입력해주세요.");
                if (!this.addiction.code) throw new Error("5대 중독을 선택해주세요.");
                if (!this.addiction.themeColor) throw new Error("테마컬러를 설정해주세요.")
                if (!this.addiction.thumb) throw new Error("테스트 썸네일을 선택해주세요.");

                for (let i = 0; i < this.addiction.scoreGroups.length; i++) {
                    if (!this.addiction.scoreGroups[i].group || !this.addiction.scoreGroups[i].maxScore) {
                        throw new Error("검사점수를 설정해주세요.")
                    }
                }

                for (let i = 0; i < this.addiction.questions.length; i++) {
                    if (!this.addiction.questions[i].question) {
                        throw new Error(`질문${i + 1}의 질문을 입력해주세요.`)
                    }
                    if (!this.addiction.questions[i].content) {
                        throw new Error(`질문${i + 1}의 내용을 입력해주세요.`)
                    } 
                    // for(let j = 0; j < this.addiction.questions[i].answers.length; j++) {
                    //     if (!this.addiction.questions[i].answers[j].answer || !this.addiction.questions[i].answers[j].score1 || !this.addiction.questions[i].answers[j].score2 || !this.addiction.questions[i].answers[j].score3) {
                    //         throw new Error(`질문${i + 1} 선택지${j + 1}을 입력해주세요.`)
                    //     }
                    // }
                }

                for (let i = 0; i < this.addiction.results.length; i++) {
                    if (!this.addiction.results[i].status) {
                        throw new Error(`${this.addiction.results[i].score}점 결과의 테스터상태를 입력해주세요.`)
                    }
                    if (!this.addiction.results[i].need1 || !this.addiction.results[i].need2) {
                        throw new Error(`${this.addiction.results[i].score}점 결과의 조치사항을 입력해주세요.`)
                    }
                    if (!this.addiction.results[i].content) {
                        throw new Error(`${this.addiction.results[i].score}점 결과의 내용을 입력해주세요.`)
                    }
                }
                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        questionviewValidate() {
            try {
                let totalScore = 0;
                for (let i = 0; i < this.addiction.scoreGroups.length; i++) {
                    if (!this.addiction.scoreGroups[i].group || !this.addiction.scoreGroups[i].maxScore) {
                        throw new Error("검사점수를 설정해주세요.")
                    }
                    totalScore = totalScore + Number(this.addiction.scoreGroups[i].maxScore)
                    if(i  === this.addiction.scoreGroups.length - 1) {
                        if(totalScore !== 300) {
                            throw new Error("검사점수의 총점수는 300점입니다.")
                        }
                    }
                }
                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                let { addiction } = this.addiction._id ? await api.console.addictions.put({ ...this.addiction, image: null }) : await api.console.addictions.post({ ...this.addiction, thumb: null });

                ////////////////////////////////////////////////////////////////
                // 4. 썸네일 저장
                ////////////////////////////////////////////////////////////////
                if (this.addiction.thumb) await api.console.addictions.thumb.post(addiction._id, this.addiction.thumb);

                alert("저장되었습니다");
                this.$router.push(`/console/addiction?type=${this.type}`);
            }
        },

        async removeImage() {
            try {
                if (confirm("상품 썸네일을 삭제하시겠습니까?")) {
                    if (this.addiction._id) await api.console.addictions.thumb.delete(this.addiction._id);
                    this.addiction.thumb = null;
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        addQuestion() {
            this.addiction.questions.push({
                question: null,
                content: null,
                answers: [
                    {
                        answer: null,
                        score1: null,
                        score2: null,
                        score3: null,
                    },
                ],
            });
        },
        removeQuestion() {
            this.addiction.questions.pop();
        },
        addResult() {
            this.addiction.results.push({
                score: this.addiction.results[this.addiction.results.length - 1].score + 5,
                status: null,
                need1: null,
                need2: null,
                content: null,
            });
        },
        removeResult() {
            this.addiction.results.pop();
        },
        openQuestionview(item, scoreGroups) {
            if(this.questionviewValidate()) {
                this.$refs.questionview.open(item, scoreGroups)
                
            }
        },
        select(selected) {
            this.selected = selected;
            this.addiction._recommendContent = selected._id;
        }
    },
    computed: {
        type() {
            return this.$route.query.type;
        }
    }
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
