<template>
    <v-dialog v-model="dialog" persistent max-width="900">
        <v-card height="100%">
            <v-card-title>
                {{ title }} 등록
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-row no-gutters>
                    <v-col>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-text-field v-model="node.label" :label="title" :placeholder="title" persistent-placeholder hide-details />
                                </v-col>
                                <v-col v-if="['result'].includes(node.type)" class="mr-3">
                                    <v-select v-model="node.hashTag" :items="hashtags" label="리커버스 추천 해시태그" item-text="name" item-value="code" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row v-if="['question', 'result'].includes(node.type)" no-gutters class="mt-3">
                                <v-col class="mr-3">
                                    이미지 에디터
                                    <naver-smarteditor v-model="node.content" v-if="dialog" class="mt-2" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn large color="primary" @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
	components: {
		NaverSmarteditor
	},
	data() {
		return {
			dialog: false,
			node: null,
            hashtags: [],
		}
	},
    async mounted() {
        await this.init();
    },
	methods: {
        async init() {
            let { hashtags } = await api.console.users.hashtag.gets()
            this.hashtags = hashtags;
        },
		open(node) {
			this.node = node;
			this.dialog = true;
		},

		close() {
			this.dialog = false;
            this.$emit("node", this.node)
		}
	},
    computed: {
        title() {
            if (this.node.type === "question") return "질문"
            if (this.node.type === "result") return "결과"
            if (this.node.type === "answer") return "답변"
        }
    }
}

</script>