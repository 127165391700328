<template>
    <console-content max-width="1024">
        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="tit tit--lg">리커버 직업 관리</v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-1">
                    <v-toolbar color="app-bar lighten-1" dense>
                        <v-toolbar-title class="subtitle-2">직업</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" fab dark small absolute bottom right @click="$refs.jobDialog.open()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-draggable-treeview v-if="jobs.length" v-model="jobs" item-key="_id" children="children" group="jobs" @click="item => job = item" @input="sort().then(search)" dense></v-draggable-treeview>
                        <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card :disabled="!job?._id" class="mx-1">
                    <v-card-title class="tit tit--xs">직업 기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="job._id" label="_id" persistent-placeholder hide-details disabled></v-text-field>
                        <v-text-field v-model="job.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <v-text-field v-model="job.code" label="카테고리 코드(영문)" persistent-placeholder hide-details class="mt-4"></v-text-field>
                    </v-card-text>
                </v-card>

                <v-row class="mt-0 px-1">
                    <v-col md="auto">
                        <v-btn color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="auto">
                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <job-dialog ref="jobDialog" @save="search"></job-dialog>
    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import jobDialog from "@/components/console/user/job/job-dialog.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor,
        jobDialog,
    },
    data(){
        return {
            ready: false,

            job: {},
            jobs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
            if(this.job._id){
                let { job } = await api.console.users.jobs.get(this.job);
                this.job = job;
            }
            this.ready = true;
        },
        async search(){
            let { jobs } = await api.console.users.jobs.gets({
                headers: { limit: 0 }
            });

            this.jobs = jobs;
            this.$refs.jobDialog.init();
        },
        async save(){
            this.job._id ? await api.console.users.jobs.put(this.job) : await api.console.users.jobs.post();
            alert("저장되었습니다");
        },
        async remove(){
            try {
                await api.console.users.jobs.delete(this.job);
                alert("삭제되었습니다");
                this.$router.go(0);
                this.search();
                
            } catch (error) {
                alert(error.message)
            }
        },
        async sort(jobs = this.jobs, depth = 1, _parents = []){
            for(let job of jobs){
                await api.console.users.jobs.put({
                    ...job,
                    depth,
                    _children: job.children.map(child => child._id),
                    _parents
                });
                if(job.children) this.sort(job.children, depth + 1, [...job._parents, job._id ]);
            }
        },
    },
}
</script>