var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0"
  }, _vm._l(_vm.max, function (depth, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": 12 / _vm.max,
        "sm": 12 / _vm.max
      }
    }, [_c('v-select', {
      staticClass: "mt-0",
      attrs: {
        "items": _vm.items(depth),
        "item-text": "name",
        "item-value": "_id",
        "value": _vm.values[depth - 1],
        "label": `${depth}차 카테고리`,
        "persistent-placeholder": "",
        "dense": _vm.dense,
        "hide-details": ""
      },
      on: {
        "input": _vm.input
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }