<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.params._solution" class="headline text-start mt-8">영상 등록</div>
            <div v-else class="headline text-start mt-8">영상 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row v-if="$route.params._solution">
                        <v-col cols="6" class="py-0">
                            <v-text-field :value="(board.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-datetime-field :value="board.createdAt ? board.createdAt.toDateTime() : null" label="작성일" persistent-placeholder hide-details @input="(value) => (board.createdAt = value)"></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board._category" :items="category.children" item-text="name" item-value="_id" label="카테고리 선택" persistent-placeholder hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col :cols="6" class="py-0">
                            <v-text-field v-model="board.hashTags" label="해쉬태그" placeholder="ex) 건강, 행복, 감성 = #건강 #행복 #감성" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col :cols="6" class="py-0">
                            <v-select v-model="board._user" :items="recovers" item-text="recover.name" item-value="_id" label="리커버" persistent-placeholder hide-details></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 font-weight-bold">
                    <span>main 노출여부 설정 (미노출/노출)</span>
                    <v-spacer />
                    <v-switch v-model="board.onDisplay" hide-details class="mt-0" />
                </v-card-title>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="board.thumb" max-width="96" max-height="96" :src="createObjectURL(board.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="board.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
    },
    data() {
        return {
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                _category: this.$route.query._category,

                code: "content",

                subject: null,
                content: null,
                hashTags: null,
                approvalStatus: "승인",
                onDisplay: false,

                writer: {
                    name: null,
                    phone: null,
                    email: null,
                },

                thumb: null,
            },
            category: {},

            approvalItems: [
                { text: "승인", value: "승인" },
                { text: "미승인", value: "미승인" },
                { text: "승인불가", value: "승인불가" },
            ],
            displayItems: [
                { text: ":: 전체 ::", value: null },
                { text: "노출", value: true },
                { text: "미노출", value: false },
            ],

            recovers: null
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let { users: recovers } = await api.console.users.gets({
                    params: { levelCode: "expert" }
                })
                this.recovers = recovers;

                let { categories } = await api.console.boards.categories.gets({ params: { code: "content", depth: 1 } })
                this.category = categories[0]
                if (this.$route.params._solution) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._solution });

                    if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, ...board } = this.board;

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.console.boards.put(board) : await api.console.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.boards.postThumb(board, thumb);

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    }
};
</script>
