var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" 추천 콘텐츠 설정 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-card', {
    staticClass: "elevation-1 mt-2 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("검색필터")]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.searchKeys,
      "label": "검색조건",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "검색어",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        (_vm.page = 1) && _vm.search();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1), _c('v-card', {
    staticClass: "elevation-1 mt-2 mx-1"
  }, [_c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.boardsHeaders,
      "items": _vm.boards,
      "single-select": _vm.singleSelect,
      "show-select": "",
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.thumb",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          staticClass: "ma-auto",
          attrs: {
            "src": item.thumb,
            "max-width": "96",
            "height": "96",
            "contain": ""
          }
        })];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.createdAt ? _vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "") + " ")];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("확인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }