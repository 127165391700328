<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="px-1">
        <div class="headline text-start mt-8">
            <span v-if="user._id">회원 상세</span>
            <span v-else>회원 등록</span>
        </div>

        <v-card v-if="user.levelCode === 'expert'" class="mx-1 mt-3">
            <v-card-title class="tit tit--xs">리커버 정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.recover.name" label="이름" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.recover.job" label="직업" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select v-model="user.recover.category" :items="jobs" label="직업 카테고리" item-text="name" item-value="code" persistent-placeholder hide-details></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.recover.thumb.name" label="썸네일" persistent-placeholder hide-details readonly @click="$refs.file.$refs.input.click()"></v-text-field>
                        <v-file-input accept=".jpg,.png" class="d-none" ref="file" @change="upload"></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select v-model="user.recover.addiction" :items="addictions" label="리커버 중독유형" item-text="name" item-value="code" persistent-placeholder hide-details />
                    </v-col>
                    <v-col>
                        <v-select v-model="user.recover.hashTag" :items="hashtags" label="리커버스 추천 해시태그" item-text="name" item-value="code" persistent-placeholder hide-details />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        소개글
                        <naver-smarteditor v-model="user.recover.introduction" class="mt-1" ></naver-smarteditor>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mx-1 mt-3">
            <v-card-title class="tit tit--xs">기본정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.username" label="아이디" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-password-field v-model="user.password" label="비밀번호" persistent-placeholder hide-details></v-password-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.name" label="이름" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.phone" label="연락처" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.email" label="이메일" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.address1" label="기본주소" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select v-model="user.levelCode" :items="levelCodes" label="회원등급" persistent-placeholder hide-details></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.bornAt" label="생년월일" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.sex" label="성별" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions />
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

            <v-row justify="center" class="mx-1 my-4">
                <v-col md="auto" sm="auto"> </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="setAddress"></daum-postcode>
    </v-layout>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";
const { COMPANY, PERSON } = USER_TYPES;
const notifyMethods = Object.values(USER_NOTIFY_METHODS);

const headers_notify = [
    { text: "유형", value: "method", formatter: (value) => notifyMethods.find((item) => item.value == value)?.text },
    { text: "연락처", value: "contact" },
    { text: "수신동의", value: "enabled", formatter: (value) => (value ? "동의" : "미동의") },
];

export default {
    components: {
        DaumPostcode,
        VPasswordField,
        NaverSmarteditor
    },
    data() {
        return {
            levels: [],
            user: {
                _id: this.$route.params._user,
            },

            window,
            PERSON,
            COMPANY,
            headers_notify,

            levelCodes: [
                { text: "일반회원", value: "normal" },
                { text: "유경험자", value: "experience" },
                { text: "전문가", value: "expert" },
            ],
            jobs: [],
            addictions: [],
            hashtags: [],
            recoverCategories: [
                { text: "의사", value: "의사" },
                { text: "변호사", value: "변호사" },
                { text: "상담사", value: "상담사" }
            ]
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.user._id) {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                
                if (user?.bornAt) user.bornAt = user.bornAt.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();
                let { jobs } = await api.console.users.jobs.gets()
                let { addictions } = await api.console.users.addiction.gets()
                let { hashtags } = await api.console.users.hashtag.gets()
                this.addictions = addictions;
                this.hashtags = hashtags;
                this.jobs = jobs
                this.user = user
            }

            var { levels } = await api.console.levels.gets();
            this.levels = levels;
        },

        validate() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                if (this.user._id) {
                    await api.console.users.put({
                        ...this.user,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                } else {
                    await api.console.users.post({
                        ...this.user,
                        username: this.user.email,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                }

                alert("저장되었습니다");
                this.$router.push(`/console/users/members`);
            }
        },

        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },

        async upload(file) {
            let { src, name } = await api.resource.post(file, `/res/users`);
            this.$set(this.user.recover, "thumb", { src, name })
        },
    },
};
</script>
