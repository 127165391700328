var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " 등록 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.title,
      "placeholder": _vm.title,
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.node.label,
      callback: function ($$v) {
        _vm.$set(_vm.node, "label", $$v);
      },
      expression: "node.label"
    }
  })], 1), ['result'].includes(_vm.node.type) ? _c('v-col', {
    staticClass: "mr-3"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.hashtags,
      "label": "리커버스 추천 해시태그",
      "item-text": "name",
      "item-value": "code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.node.hashTag,
      callback: function ($$v) {
        _vm.$set(_vm.node, "hashTag", $$v);
      },
      expression: "node.hashTag"
    }
  })], 1) : _vm._e()], 1), ['question', 'result'].includes(_vm.node.type) ? _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-3"
  }, [_vm._v(" 이미지 에디터 "), _vm.dialog ? _c('naver-smarteditor', {
    staticClass: "mt-2",
    model: {
      value: _vm.node.content,
      callback: function ($$v) {
        _vm.$set(_vm.node, "content", $$v);
      },
      expression: "node.content"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("확인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }