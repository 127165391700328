<template>
    <console-content max-width="1024">
        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="tit tit--lg">카테고리 관리</v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-1">
                    <v-toolbar color="app-bar lighten-1" dense>
                        <v-toolbar-title class="subtitle-2">카테고리</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" fab dark small absolute bottom right @click="$refs.categoryDialog.open()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-draggable-treeview v-if="categories.length" v-model="categories" item-key="_id" children="children" group="categories" @click="item => category = item" @input="sort().then(search)" dense></v-draggable-treeview>
                        <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                    </v-card-text>

                </v-card>
                <v-card>
                    <v-btn color="accent" dark small absolute bottom right @click="$refs.categoryIntegratedDialog.open()">
                        카테고리 통합
                    </v-btn>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card :disabled="!category?._id" class="mx-1">
                    <v-card-title class="tit tit--xs">카테고리 기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="category._id" label="_id" persistent-placeholder hide-details disabled></v-text-field>
                        <v-text-field v-model="category.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <v-text-field v-model="category.code" label="카테고리 코드(영문)" persistent-placeholder hide-details class="mt-4"></v-text-field>
                    </v-card-text>
                </v-card>

                <v-row class="mt-0 px-1">
                    <v-col md="auto">
                        <v-btn color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="auto">
                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-col>
            <v-col>* 카테고리 통합 기능설명</v-col>
            <v-col>* 1차 카테고리는 통합할 수 없습니다</v-col>
            <v-col>* ex) 칼럼과 영상 통합 X</v-col>
            <v-col>* 서로다른 1차 카테고리의 2차, 3차 카테고리는 통합할 수 없습니다</v-col>
            <v-col>* ex) 칼럼/경험담과 영상/경험담 통합 X</v-col>
            <v-col>* ex) 영상/경험담과 영상/정보 통합 o</v-col>
            <v-col>* 커뮤니티 카테고리는 3차 카테고리만 통합할 수 있습니다</v-col>
        </v-col>
        <category-dialog ref="categoryDialog" @save="search"></category-dialog>
        <category-integrated-dialog ref="categoryIntegratedDialog" @save="search"></category-integrated-dialog>
    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import CategoryDialog from "@/components/console/boards/category/category-dialog.vue";
import CategoryIntegratedDialog from "@/components/console/boards/category/category-integrated-dialog.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor,
        CategoryDialog,
        CategoryIntegratedDialog
    },
    data(){
        return {
            ready: false,

            category: {},
            categories: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
            if(this.category._id){
                let { category } = await api.console.boards.categories.get(this.category);
                this.category = category;
            }
            this.ready = true;
        },
        async search(){
            let { categories } = await api.console.boards.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 }
            });

            this.categories = categories;
            this.$refs.categoryDialog.init();
        },
        async save(){
            this.category._id ? await api.console.boards.categories.put(this.category) : await api.console.boards.categories.post();
            alert("저장되었습니다");
        },
        async remove(){
            try {
                if(this.category.depth === 1) throw new Error("최상위 카테고리는 삭제할 수 없습니다.")
                await api.console.boards.categories.delete(this.category);
                alert("삭제되었습니다");
                this.$router.go(0);
                this.search();
                
            } catch (error) {
                alert(error.message)
            }
        },
        async sort(categories = this.categories, depth = 1, _parents = []){
            for(let category of categories){
                await api.console.boards.categories.put({
                    ...category,
                    depth,
                    _children: category.children.map(child => child._id),
                    _parents
                });
                if(category.children) this.sort(category.children, depth + 1, [...category._parents, category._id ]);
            }
        },
    },
}
</script>