<template>
    <v-row class="pa-0">
        <v-col v-for="(depth, index) in limit" :key="index" cols="12" :md="12/limit" :sm="12/limit" class="py-3 py-sm-0">
            <v-select :items="items(depth)" item-text="name[ko]" item-value="_id" :value="select(depth)" @input="input" :label="`${depth}차 카테고리`" persistent-placeholder :dense="dense" hide-details :disabled="!!allowDepth.length && !allowDepth.includes(depth)" ></v-select>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        value: { default: null },
        categories: { type: Array, default() { return []; } },
        returnObject: { type: Boolean, default: false },
        allowDepth: { type: Array, default() { return []; } }
    },
    data() {
        return {
            category: null
        };
    },
    created(){
        this.category = this.findCategory(this.returnObject ? this.value?._id : this.value);
    },
    methods: {
        input(_category){
            this.category = this.findCategory(_category);
            this.$emit('input', this.returnObject ? this.category : _category);
        },
        findCategory(_category, categories = null){
            if(!categories) categories = this.categories;

            for(var category of categories){
                if(category._id == _category) return category;
                else if(category.children){
                    var result = this.findCategory(_category, category.children);
                    if(result) return result;
                }
            }

            return null;
        },
        items(depth, categories = this.categories){
            if(depth == 1) return categories;
            if(this.category){
                for(var category of categories){
                    if(category._id == this.category._id){
                        if(depth <= this.category.depth) return categories;
                        else if(this.category.depth + 1 == depth) return category.children;
                    }
                    else if(category.children){
                        var result = this.items(depth, category.children);
                        if(result.length) return depth <= category.depth ? categories : result;
                    }
                }
            }

            return [];
        },
        select(depth){
            if(this.category) return [...this.category._parents, this.category._id][depth - 1];
            return null;
        },
        findDepth(categories = null, depth = 1){
            var maxDepth = depth;
            if(!categories) categories = this.categories;
            for(var category of categories){
                if(category.children && category.children.length){
                    var result = this.findDepth(category.children, depth + 1);
                    if(maxDepth < result) maxDepth = result;
                }
            }
            return maxDepth;
        },
    },
    computed: {
        limit(){
            return this.findDepth();
        }
    },
    watch: {
        value(){
            this.category = this.findCategory(this.returnObject ? this.value?._id : this.value);
        }
    }
}
</script>
