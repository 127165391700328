<template>
    <v-container fluid fill-height class="console-banners">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                        배너관리
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <!-- <div class="caption primary--text">
                    * PC 배너 사이즈 1920*760(px) / 모바일 배너 사이즈 720*1180(px)
                </div> -->

                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text class="pb-0">
                            <v-row class="pa-0">
                                <v-col cols="12" md="4" sm="4">
                                    <v-select v-model="filter.code" :items="codeKeys" label="코드" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-select v-model="filter.onDisplay" :items="onDisplayKeys" label="노출여부" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-select v-model="filter.levelCode" :items="levelCodeKeys" label="회원등급" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @input="search(true)"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="(page = 1) && search(false)"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="bannersHeaders" :items="banners" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.no="{ item }">
                        {{ (page - 1) * limit + (+banners.indexOf(item) + 1) }}
                    </template>
                    <template v-slot:item.image="{ item }">
                        <v-img :src="item.image" max-width="96" height="96" contain class="ma-auto"></v-img>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "" }}
                    </template>
                    <template v-slot:item.slides="{ item }">
                        <v-img v-for="(slide, index) in item.slides.filter((slide) => slide.image)" :key="index" :src="slide.image" max-width="96" max-height="96" contain class="d-inline-block"></v-img>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.url="{ item }">
                        <v-btn v-if="item.url" icon text :href="item.url" target="_blank">
                            <v-icon>mdi-link</v-icon>
                        </v-btn>
                        <template v-else>-</template>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
            </v-responsive>
        </v-layout>

        <v-btn color="primary" fab fixed right bottom @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    created() {
        this.init();
    },
    data() {
        return {
            showSearch: true,

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            filter: {
                code: this.$route.query.code || null,
                onDisplay: this.$route.query.onDisplay || null,
                levelCode: this.$route.query.levelCode || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            bannersHeaders: [
                { text: "no", align: "center", value: "no", width: 40 },
                { text: "제목", align: "center", value: "name", width: 200 },
                { text: "코드", align: "center", value: "code", width: 100 },
                { text: "이미지", align: "center", value: "image", width: 100 },
                { text: "링크", align: "center", value: "url", width: 120 },
                { text: "등록일자", align: "center", value: "createdAt", width: 200 },
                { text: "actions", align: "center", value: "actions", width: 100 },
            ],

            codeKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "사이드", value: "side" },
                { text: "하단", value: "buttom" },
            ],

            onDisplayKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "미노출", value: false },
                { text: "노출", value: true },
            ],

            levelCodeKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "비로그인", value: "no" },
                { text: "일반회원", value: "normal" },
                { text: "유경헙자", value: "experience" },
                { text: "전문가", value: "professional" },
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "제목", value: "name" },
            ],
            banners: [],
            editItem: null,
        };
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
                return;
            }
            try {
                var { summary, banners } = await api.console.banners.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: {
                        ...this.filter,
                    },
                });

                this.banners = banners;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        create() {
            this.$router.push("/console/banners/create");
        },

        edit(banner) {
            this.$router.push(`/console/banners/${banner._id}`);
        },

        async save() {
            try {
                var { banner } = this.editItem._id ? await api.console.banners.put(this.editItem) : await api.console.banners.post(this.editItem);

                await this.search();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async remove(banner) {
            if (confirm("배너를 삭제하시겠습니까?")) {
                await api.console.banners.delete(banner);
                await this.search();
            }
        },

        async removeImage(banner, index) {
            try {
                if (confirm("배너 이미지를 삭제하시겠습니까?")) {
                    if (banner._id) await api.console.banners.deleteImage(banner._id, index);
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {}
        },
    },
};
</script>
