var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1280"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.excelSheet ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품 업로드")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-data-table', {
    attrs: {
      "items": _vm.products,
      "headers": _vm.headers
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }