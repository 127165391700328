<template>
    <v-dialog v-model="dialog" max-width="1200">
        <v-card>
            <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="pa-0">
                    <v-col cols="12" md="4" sm="4">
                        <v-select v-model="filter.sex" :items="sexItems" label="성별" hide-details persistent-placeholder></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-select v-model="filter.startAge" :items="ageItems" label="시작 나이" hide-details persistent-placeholder></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-select v-model="filter.endAge" :items="ageItems" label="끝 나이" hide-details persistent-placeholder></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
            </v-card-actions>
            <v-divider />
            <v-card-title class="tit tit--xs font-weight-bold">{{ addiction?.subject }} 통계</v-card-title>
            <v-chart :data="testStatistics.count" :x-axis-data="testStatistics.xAxisData"/>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import VChart from "@/components/console/statistic/v-chart.vue";

export default {
    components: {
        VChart
    },
    data() {
        return {
            filter: {
                sex: null,
                startAge: null,
                endAge: null
            },
            dialog: false,
            addiction: null,
            statistics: null,
            sexItems: [
                { text: ":: 전체 ::", value: null },
                { text: "여성", value: "여성" },
                { text: "남성", value: "남성" },
            ],
        };
    },
    methods: {
        async init() {
            await this.search()
        },
        async search() {
            let { statistics } = await api.console.addictions.statistics({
                _id: this.addiction._id,
                params: {
                    ...this.filter
                }
            });
            this.statistics = statistics;
        },
        async open(addiction) {
            this.addiction = addiction;
            await this.init();
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    },
    computed: {
        ageItems() {
            let result = [{ text: "전체", value: null }]
            for(let i = 1; i <= 100; i++) {
                result.push({ text: `${i} 세`, value: i })
            }
            return result
        },
        testStatistics() {
            let statistics = this?.statistics
            let xAxisData = statistics?.map(statistic => `${statistic?.totalScore} 점`) || [];
            let count = statistics?.map(statistic => statistic?.count) || [];
            return {
                xAxisData,
                count
            }
        }
    },
    watch: {
        dialog() {
            if(!this.dialog) {
                this.filter = {
                    sex: null,
                    startAge: null,
                    endAge: null
                }
            }
        }
    }
};
</script>
