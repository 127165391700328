var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "headline text-start mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.addiction._id ? "테스트 상세보기" : "테스트 등록"))])]), _c('v-spacer')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "테스트 제목",
      "placeholder": "테스트 제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.addiction.subject,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "subject", $$v);
      },
      expression: "addiction.subject"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "placeholder": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.addiction.code,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "code", $$v);
      },
      expression: "addiction.code"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("테마컬러 설정")]), _c('v-card-text', [_c('v-color-picker', {
    attrs: {
      "dot-size": "25",
      "mode": "hexa",
      "swatches-max-height": "200",
      "hide-mode-switch": ""
    },
    model: {
      value: _vm.addiction.themeColor,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "themeColor", $$v);
      },
      expression: "addiction.themeColor"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_c('span', [_vm._v("인기테스트 설정")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.addiction.isPopular,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "isPopular", $$v);
      },
      expression: "addiction.isPopular"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("추천콘텐츠 (고정)")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "콘텐츠 _id",
      "placeholder": "추천할 콘텐츠 _id을 입력해주세요",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.addiction._recommendContent,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "_recommendContent", $$v);
      },
      expression: "addiction._recommendContent"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("해쉬태그 설정")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "해쉬태그",
      "placeholder": "ex) 건강, 행복, 감성 = #건강 #행복 #감성",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.addiction.hashTags,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "hashTags", $$v);
      },
      expression: "addiction.hashTags"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "256"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('addiction-thumb', {
    attrs: {
      "label": "썸네일"
    },
    on: {
      "remove": _vm.removeImage
    },
    model: {
      value: _vm.addiction.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "thumb", $$v);
      },
      expression: "addiction.thumb"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("검사점수 설정")]), _c('v-card-text', _vm._l(_vm.addiction.scoreGroups, function (scoreGroup, index) {
    return _c('v-row', {
      key: index,
      staticClass: "mt-3"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": `항목${index + 1}`,
        "placeholder": "ex) 빈도수",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: scoreGroup.group,
        callback: function ($$v) {
          _vm.$set(scoreGroup, "group", $$v);
        },
        expression: "scoreGroup.group"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "최대점수",
        "placeholder": "최대점수",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: scoreGroup.maxScore,
        callback: function ($$v) {
          _vm.$set(scoreGroup, "maxScore", $$v);
        },
        expression: "scoreGroup.maxScore"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "콘텐츠 _id",
        "placeholder": "추천할 콘텐츠 _id을 입력해주세요",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: scoreGroup._recommendContent,
        callback: function ($$v) {
          _vm.$set(scoreGroup, "_recommendContent", $$v);
        },
        expression: "scoreGroup._recommendContent"
      }
    })], 1)], 1);
  }), 1), _c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("동률점수 추천콘텐츠")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "콘텐츠 _id",
      "placeholder": "추천할 콘텐츠 _id을 입력해주세요",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.addiction._recommendContent2,
      callback: function ($$v) {
        _vm.$set(_vm.addiction, "_recommendContent2", $$v);
      },
      expression: "addiction._recommendContent2"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("질문 정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, _vm._l(_vm.addiction.questions, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": `질문${index + 1}`,
        "placeholder": `질문${index + 1}`,
        "readonly": "",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      on: {
        "click": function ($event) {
          return _vm.openQuestionview(item, _vm.addiction.scoreGroups);
        }
      },
      model: {
        value: item.question,
        callback: function ($$v) {
          _vm.$set(item, "question", $$v);
        },
        expression: "item.question"
      }
    })], 1);
  }), 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-spacer'), _vm.addiction.questions.length !== 1 ? _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.removeQuestion
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addQuestion
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("결과 정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, _vm._l(_vm.addiction.results, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": `${item.score} 점`,
        "readonly": "",
        "hide-details": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$refs.resultview.open(item);
        }
      },
      model: {
        value: item.score,
        callback: function ($$v) {
          _vm.$set(item, "score", $$v);
        },
        expression: "item.score"
      }
    })], 1);
  }), 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-spacer'), _vm.addiction.results.length !== 1 ? _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.removeResult
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1) : _vm._e(), _vm.addiction.results.length !== 21 ? _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addResult
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('addiction-question-view', {
    ref: "questionview"
  }), _c('addiction-result-view', {
    ref: "resultview"
  }), _c('addiction-recommed-view', {
    ref: "recommendview",
    on: {
      "select": function (value) {
        return _vm.select(value);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }