<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="px-1">
            <div class="headline text-start mt-8">
                <span>인증 상세</span>
            </div>

        <v-card class="mx-1 mt-3">
            <v-card-title class="tit tit--xs">기본정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select v-model="levelup.code" :items="codeItems" label="인증유형" persistent-placeholder hide-details readonly></v-select>
                    </v-col>
                    <v-col>
                        <v-select v-model="levelup.category" :items="categoryItems" label="카테고리" persistent-placeholder hide-details readonly></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="levelup.user.username" label="회원아이디" persistent-placeholder hide-details readonly></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="levelup.user.nickname" label="회원닉네임" persistent-placeholder hide-details readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select v-model="levelup.status" :items="statusItems" label="승인상태" persistent-placeholder hide-details></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea  v-model="levelup.content" label="내용" persistent-placeholder hide-details readonly></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions />
        </v-card>

        <v-card class="mt-6">
                <v-card-title class="subtitle-1">인증 파일</v-card-title>
                <v-card-text>
                    <v-col>
                        <v-btn :href="levelup.file" download color="primary">인증 파일 다운로드</v-btn>
                    </v-col>
                </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

            <v-row justify="center" class="mx-1 my-4">
                <v-col md="auto" sm="auto"> </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        DaumPostcode,
        VPasswordField
    },
    data() {
        return {
            levelup: null,

            codeItems: [
                { text: "유경험자", value: "experience" },
                { text: "전문가", value: "expert" }
            ],
            categoryItems: [
                { text: "약물", value: "drug" },
                { text: "성", value: "sex" },
                { text: "도박", value: "gambling" },
                { text: "인터넷", value: "internet" },
                { text: "알코올", value: "alcohol" },
            ],
            statusItems: [
                { text: "승인", value: "승인" },
                { text: "미승인", value: "미승인" },
                { text: "승인거부", value: "승인거부" },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { levelup } = await api.console.users.levelups.get({ _id: this._levelup })
            this.levelup = levelup
            console.log(levelup)
        },

        validate() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                await api.console.users.levelups.put(this.levelup)

                alert("저장되었습니다");
                this.$router.push(`/console/users/levelup`);
            }
        },

    },
    computed: {
        _levelup() {
            return this.$route.params._levelup;
        }
    }
};
</script>
