<template>
    <v-chart :options="options" style="height: 200px; width: 100%" autoresize/>
    </template>
    
    <script>
    import ECharts from "vue-echarts";
    
    export default {
        components: {
            "v-chart": ECharts,
        },
        props: {
            xAxisData: { type: Array, default: () => [] },
            data: { type: Array, default: () => [] },
        },
        computed: {
            options(){
                return {
                    color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                    grid: {
                        width: "90%",
                        left: "5%",
                        top: "5%",
                        bottom: "10%",
                        containLabel: true
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: true,
                        data: this.xAxisData
                    },
                    yAxis: {
                        type: "value",
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    series: [{
                        data: this.data,
                        type: "line",
                        areaStyle: {}
                    }]
                };
            },
        }
    }
    </script>
    
    <style scoped>
    * >>> .echarts {
      width: 100%;
      height: 100%;
    }
    </style>