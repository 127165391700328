<template>
    <v-dialog v-model="show" width="560">
        <v-card v-if="show">
            <v-card-title class="tit tit--xs font-weight-bold" primary-title>카테고리 통합</v-card-title>
            <v-card-text class="pb-0">
                <div>통합할 카테고리</div>
                <category-select v-model="parent1" :categories="categories" return-object :allowDepth="[1, 2]"></category-select>
            </v-card-text>
            <v-card-text class="pb-0 mt-1">
                <div>통합될 카테고리</div>
                <category-select v-model="parent2" :categories="categories" return-object :allowDepth="[1, 2]"></category-select>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">취소</v-btn>
                <v-btn color="primary" text @click="integrated">통합</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/boards/category/category-select.vue";

export default {
    components: {
        CategorySelect
    },
    data(){
        return {
            show: false,

            categories: [],
            category: {},

            parent1: null,
            parent2: null,
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.console.boards.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
            this.category = {
                name: null,
                code: null,
            };

            this.parent = null;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        async integrated(){
            await api.console.boards.categories.integrated({ parent1: this.parent1, parent2: this.parent2 })
            alert("카테고리가 통합되었습니다")
            this.show = false;
        }
    },
    watch: {
        show(){
            if(!this.show) this.init();
        }
    }
}
</script>