<template>
    <v-dialog v-model="dialog" persistent max-width="900">
        <v-card height="100%">
            <v-card-title>
                중독테스트 결과 설정
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-row no-gutters>
                    <v-col>
                        <v-card-subtitle>점수 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-text-field v-model="result.score" label="점수" />
                                </v-col>
                                <v-col class="mr-3">
                                    <v-text-field v-model="result.status" label="테스터 상태" />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-text-field v-model="result.need1" label="조치사항1" />
                                </v-col>
                                <v-col class="mr-3">
                                    <v-text-field v-model="result.need2" label="조치사항2" />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-select v-model="result.hashTag" :items="hashtags" label="리커버스 추천 해시태그" item-text="name" item-value="code" persistent-placeholder hide-details />
                                </v-col>
                                <v-col class="mr-3"></v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    이미지 에디터
                                    <naver-smarteditor v-model="result.content" v-if="dialog" class="mt-2"></naver-smarteditor>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-divider />
                <v-row no-gutters>
                    <v-col>
                        <v-card-subtitle>추천콘텐츠 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-text-field v-model="result._recommendContent" label="콘텐츠 _id" placeholder="추천할 콘텐츠 _id을 입력해주세요" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn large color="primary" @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
        <addiction-recommed-view ref="recommendview" @select="value => select(value)"></addiction-recommed-view>
    </v-dialog>
</template>

<script>
import api from "@/api";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import addictionRecommedView from "@/components/console/addiction/addiction-recommend-view.vue";

export default {
    components: {
        NaverSmarteditor,
        addictionRecommedView
    },
    data() {
        return {
            dialog: false,
            selected: {
                _id: null,
                subject: null,
            },
            result: {},
            hashtags: []
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { hashtags } = await api.console.users.hashtag.gets()
            this.hashtags = hashtags;
        },
        open(result) {
            this.dialog = true;
            this.result = result;
        },
        close() {
            this.result = [];
            this.dialog = false;
        },
        select(selected) {
            this.selected = selected;
            this.result._recommendContent = selected._id;
        },
    },
};
</script>
