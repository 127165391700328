<template>
    <v-container fluid fill-height class="console-banners">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                        테스트 관리
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded><v-icon small class="mr-2">mdi-filter-letiant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card class="elevation-1 mt-2 mx-1">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text class="pb-0">
                            <v-row class="pa-0">
                                <v-col cols="12" md="4" sm="4">
                                    <v-select v-model="filter.code" :items="categories" item-text="name" item-value="name" label="카테고리" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-select v-model="filter.isPopular" :items="popularItems" label="인기여부" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder @input="search(true)"></v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @input="search(true)"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="(page = 1) && search(false)"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="addictionHeaders" :items="addictions" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.no="{ item }">
                        {{ (page - 1) * limit + (+addictions.indexOf(item) + 1) }}
                    </template>
                    <template v-slot:item.thumb="{ item }">
                        <v-img :src="item.thumb" max-width="96" height="96" contain class="ma-auto"></v-img>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "" }}
                    </template>
                    <template v-slot:item.statistics="{ item }">
                        <v-icon small class="mr-2" @click="$refs.statistics.open(item)">mdi-chart-timeline-variant</v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
            </v-responsive>
        </v-layout>

        <v-btn color="primary" fab fixed right bottom @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
        <addiction-statistics-view ref="statistics"></addiction-statistics-view>
    </v-container>
</template>

<script>
import api from "@/api";
import addictionStatisticsView from '@/components/console/addiction/addiction-statistics-view.vue';

export default {
    components: {
        addictionStatisticsView
    },
    data() {
        return {
            page: +this.$route.query.page || 1,
            limit: 10,
            pageCount: 0,

            filter: {
                code: this.$route.query.code || null,
                isPopular: this.$route.query.isPopular || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            addictions: [],
            addictionHeaders: [
                { text: "no", align: "center", value: "no", width: 40 },
                { text: "테스트 제목", align: "center", value: "subject", width: 200 },
                { text: "썸네일", align: "center", value: "thumb", width: 100 },
                { text: "등록일자", align: "center", value: "createdAt", width: 200 },
                { text: "통계", align: "center", value: "statistics", width: 100 },
                { text: "actions", align: "center", value: "actions", width: 100 },
            ],

            categories: null,

            popularItems: [
                { text: ":: 전체 ::", value: null },
                { text: "인기 테스트", value: "true" },
                { text: "일반 테스트", value: "false" },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제목", value: "subject" },
            ],
        };
    },

    async mounted() {
        await this.init();
    },

    methods: {
        async init() {
            try {
                let { categories } = await api.console.addictions.categories.gets({
                    params: { dapth: 1 }
                })
                this.categories = categories
                await this.search(false);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
                return;
            }
            try {
                let { summary, addictions } = await api.console.addictions.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: {
                        ...this.filter,
                    },
                });

                this.addictions = addictions;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        create() {
            this.$router.push(`/console/addiction/create`);
        },

        async remove(addiction) {
            if (confirm("배너를 삭제하시겠습니까?")) {
                await api.console.addictions.delete(addiction);
                await this.search();
            }
        },

        edit(addiction) {
            this.$router.push(`/console/addiction/${addiction._id}`);
        },
    },
};
</script>
