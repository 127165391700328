var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "pa-3",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', _vm._b({
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, 'v-responsive', _vm.$attrs, false), [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }