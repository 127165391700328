var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "headline text-start mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.banner._id ? "배너 상세보기" : "배너 등록"))])]), _c('v-spacer')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "placeholder": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.name,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "name", $$v);
      },
      expression: "banner.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "광고링크",
      "placeholder": "광고링크",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.url,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "url", $$v);
      },
      expression: "banner.url"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.bannerCodes,
      "label": "배너위치",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.code,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "code", $$v);
      },
      expression: "banner.code"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.levlelCodes,
      "label": "회원노출여부",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.levelCode,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "levelCode", $$v);
      },
      expression: "banner.levelCode"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.display,
      "label": "노출여부",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "onDisplay", $$v);
      },
      expression: "banner.onDisplay"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("이미지")]), _c('v-card-text', [_c('banner-image', {
    attrs: {
      "label": "이미지"
    },
    on: {
      "remove": _vm.removeImage
    },
    model: {
      value: _vm.banner.image,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "image", $$v);
      },
      expression: "banner.image"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }