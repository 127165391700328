<template>
    <v-dialog v-model="dialog" persistent max-width="900">
        <v-card height="100%">
            <v-card-title>
                추천 콘텐츠 설정
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-card class="elevation-1 mt-2 mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pb-0">
                        <!-- <v-row class="pa-0">
                            <v-col cols="12" md="4" sm="4">
                                <v-select v-model="filter.code" :items="fiveAddictions" label="5대 중독" hide-details persistent-placeholder @input="search(true)"></v-select>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-select v-model="filter.isPopular" :items="popularItems" label="인기여부" hide-details persistent-placeholder @input="search(true)"></v-select>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="elevation-1 mt-2 mx-1">
                    <v-data-table v-model="selected" :headers="boardsHeaders" :items="boards" :single-select="singleSelect" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                        <!-- <template v-slot:item.no="{ item }">
                            {{ (page - 1) * limit + (+addictions.indexOf(item) + 1) }}
                        </template> -->
                        <template v-slot:item.thumb="{ item }">
                            <v-img :src="item.thumb" max-width="96" height="96" contain class="ma-auto"></v-img>
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{ item.createdAt ? $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "" }}
                        </template>
                    </v-data-table>
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
                </v-card>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn large color="primary" @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        NaverSmarteditor,
    },
    data() {
        return {
            dialog: false,

            page: 1,
            limit: 5,
            pageCount: 0,

            singleSelect: true,
            question: [],

            scoreGroups: [],

            selected: [],
            boards: [],
            boardsHeaders: [
                { text: "콘텐츠 제목", align: "center", value: "subject", width: 100 },
                { text: "썸네일", align: "center", value: "thumb", width: 200 },
                { text: "등록일자", align: "center", value: "createdAt", width: 100 },
            ],

            filter: {
                code: "content",
                searchKey: null,
                searchValue: null,
            },
            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제목", value: "subject" },
            ],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async search() {
            try {

                let { summary, boards } = await api.console.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: {
                        ...this.filter
                    },
                });
                
                this.boards = boards;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.question = [];
            this.scoreGroups = [];
            this.dialog = false;
        },
        addAnswer() {
            this.question.answers.push({
                answer: null,
                score1: null,
                score2: null,
                score3: null,
            });
        },
        removeAnswer() {
            this.question.answers.pop();
        },
    },
    watch: {
        selected() {
            this.$emit("select", ...this.selected)
        }
    }
};
</script>
