<template>
    <v-dialog v-model="show" width="560">
        <v-card v-if="show">
            <v-card-title class="tit tit--xs font-weight-bold" primary-title>신규 해시태그 등록</v-card-title>
            <v-card-text class="pb-0">
                <v-text-field v-model="hashtag.name" label="해시태그 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                <v-text-field v-model="hashtag.code" label="해시태그 코드" persistent-placeholder hide-details class="mt-4"></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">취소</v-btn>
                <v-btn color="primary" text @click="save">등록</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

export default {
    data(){
        return {
            show: false,
            hashtag: {},
        };
    },
    mounted(){
    },
    methods: {
        async init(){

        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        async save(){
            try {
				if (!this.hashtag.name) throw new Error("이름을 입력해주세요")
				if (!this.hashtag.code) throw new Error("코드를 입력해주세요")
				
                let { hashtag } = await api.console.users.hashtag.post(this.hashtag);
    
                alert("저장되었습니다");
    
                this.$emit("save");
                this.close();
            } catch (error) {
                alert(error.message);
            }
        }
    },
    watch: {
        show(){
            if(!this.show) this.init();
        }
    }
}
</script>