<template>
    <v-row class="pt-0 pb-0" align="center">
        <v-col cols="auto" class="pt-4 pb-0">
            <v-img v-if="value" :src="createObjectURL(value)" width="128" height="128"></v-img>
            <v-responsive v-else width="128" height="128">
                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                    <v-icon>mdi-image</v-icon>
                </v-row>
            </v-responsive>
        </v-col>
        <v-col>
            <v-file-input :label="label" show-size dense accept="image/*" @change="input"></v-file-input>
            <!-- <v-btn v-if="images[index] || (product.images[index])" dense color="white" class="red--text"><v-icon class="mr-4">mdi-delete</v-icon>삭제</v-btn> -->
        </v-col>
        <v-col v-if="value" cols="auto" class="py-0 pl-0">
            <v-btn icon @click="$emit('remove')"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        value: { type: File, default: null },
        label: { type: String, default: null },
    },
    methods: {
        input(file) {
			console.log(file);
            this.$emit("input", file);
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {}
        },
    },
};
</script>
