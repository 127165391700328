<template>
    <v-container fluid fill-height class="console-faq">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">자주 묻는 궁금증</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="faqs" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template v-slot:[`item.faq`]="{ item }">
                        <v-row @click="$set(item, 'show', !item.show)">
                            <v-col cols="auto" class="text-start">[{{ item.type }}] {{ item.subject }}</v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-row v-show="item.show">
                                <v-col cols="12" class="text-start">
                                    <p v-html="item.content"></p>
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon v-on="on">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="dialog">
                        <v-card-title>
                            <span v-if="!editItem._id" class="subtitle-1">자주 묻는 궁금증 등록</span>
                            <span v-else class="subtitle-1">수정</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.subject" label="질문" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col class="py-0">
                                    <!-- <v-text-field v-model="editItem.sequence" label="순서" persistent-placeholder hide-details></v-text-field> -->
                                    <v-select v-model="editItem.category" :items="categoriesItems" label="순서" persistent-placeholder hide-details></v-select>
                                </v-col>
                            </v-row>

                            <div class="caption mt-4 mb-1">답변</div>
                            <v-row align="center">
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.reply.name" label="전문가 이름" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" class="mt-3">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="editItem.reply.thumb" max-width="96" max-height="96" :src="createObjectURL(editItem.reply.thumb)" contain></v-img>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="editItem.reply.thumb" label="전문가 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>
                            <div class="caption mt-4 mb-1">답변 내용</div>
                            <naver-smarteditor v-model="editItem.reply.content"></naver-smarteditor>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
    },
    created() {
        this.init();
    },
    data: () => {
        return {
            showSearch: false,
            dialog: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            faqs: [],
            headers: [
                { text: "카테고리", align: "center", value: "category", width: 100 },
                { text: "질문", align: "center", value: "subject", width: 300 },
                { text: "전문가 이름", align: "center", value: "reply.name", width: 200 },
                { text: "작성일자", align: "center", width: 120, value: "createdAt" },
                { text: "actions", align: "center", width: 100, value: "actions" },
            ],
            editItem: {
                id: null,
                subject: null,
                category: null,

                reply: {
                    content: null,
                    name: null,
                    thumb: null,
                },
            },
            defaultItem: {
                id: null,
                subject: null,
                category: null,

                reply: {
                    content: null,
                    name: null,
                    thumb: null,
                },
            },

            categoriesItems: [
                { text: "카테고리를 선택해주세요", value: null },
                { text: "약물", value: "약물" },
                { text: "성", value: "성" },
                { text: "도박", value: "도박" },
                { text: "인터넷", value: "인터넷" },
                { text: "알코올", value: "알코올" },
            ],
        };
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { summary, faqs } = await api.console.center.faqs.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
            });

            for (let i = 0; i < faqs.length; i++) {
                faqs[i].reply.thumb = await api.getResource(faqs[i].reply.thumb, true);
            }
            this.summary = summary;
            this.faqs = faqs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        close() {
            this.dialog = false;
        },

        create() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.editItem.sequence = this.summary.totalCount + 1;
            this.dialog = true;
        },

        edit(faq) {
            this.editItem = Object.assign({}, faq);
            this.dialog = true;
        },

        validate() {
            try {
                if (!this.editItem.subject) throw new Error("질문을 입력해주세요");
                if (!this.editItem.category) throw new Error("카테고리를 선택해주세요");
                if (!this.editItem.reply.name) throw new Error("전문가 이름을 입력해주세요");
                if (!this.editItem.reply.thumb) throw new Error("전문가 이미지를 선택해주세요");
                if (!this.editItem.reply.content) throw new Error("답변 내용을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                let { faq } = this.editItem._id ? await api.console.center.faqs.put(this.editItem) : await api.console.center.faqs.post(this.editItem);

                if (this.editItem.reply.thumb) await api.console.center.faqs.postImage(faq, this.editItem.reply.thumb);
                this.editItem = {
                    id: null,
                    subject: null,
                    category: null,

                    reply: {
                        content: null,
                        name: null,
                        thumb: null,
                    },
                }
                this.dialog = false
                await this.search();
            }
        },

        async remove(faq) {
            if (confirm("해당 질문을 삭제하시겠습니까?")) {
                await api.console.center.faqs.delete(faq);
                await this.search();
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
    watch: {
        dialog() {
            if (!this.dialog) {
                this.editedItem = Object.assign({}, this.defaultItem);
            }
        },
    },
};
</script>

<style>
.console-faq .v-data-table__mobile-row {
    height: auto !important;
}
</style>
