<template>
    <v-dialog v-model="dialog" persistent max-width="900">
        <v-card height="100%">
            <v-card-title>
                중독테스트 질문 및 선택지 설정
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-row no-gutters>
                    <v-col>
                        <v-card-subtitle>질문 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col class="mr-3">
                                    <v-text-field v-model="question.question" label="질문" placeholder="질문" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-3">
                                <v-col class="mr-3">
                                    이미지 에디터
                                    <naver-smarteditor v-model="question.content" v-if="dialog" class="mt-2"></naver-smarteditor>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-divider />
                <v-row no-gutters>
                    <v-col>
                        <v-card-subtitle>선택지 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row v-for="(item, index) in question.answers" :key="index" no-gutters>
                                <v-col class="mr-3 mt-3">
                                    <v-text-field v-model="item.answer" :label="`선택지${index + 1}`" :placeholder="`선택지${index + 1}`" persistent-placeholder hide-details />
                                </v-col>
                                <v-col class="mr-3 mt-3">
                                    <v-text-field v-model="item.score1" :label="scoreGroups[0].group" :placeholder="scoreGroups[0].group" persistent-placeholder hide-details />
                                </v-col>
                                <v-col class="mr-3 mt-3">
                                    <v-text-field v-model="item.score2" :label="scoreGroups[1].group" :placeholder="scoreGroups[1].group" persistent-placeholder hide-details />
                                </v-col>
                                <v-col class="mr-3 mt-3">
                                    <v-text-field v-model="item.score3" :label="scoreGroups[2].group" :placeholder="scoreGroups[2].group" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-spacer />
                                <v-btn v-if="question.answers?.length !== 1" color="primary" class="mr-3" @click="removeAnswer">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn color="primary" class="mr-3" @click="addAnswer">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn large color="primary" @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        NaverSmarteditor,
    },
    data() {
        return {
            dialog: false,

            question: [],

            scoreGroups: [],
        };
    },
    methods: {
        open(question, scoreGroups) {
            this.dialog = true;
            this.question = question;
            this.scoreGroups = scoreGroups;
        },
        close() {
            this.question = [];
            this.scoreGroups = [];
            this.dialog = false;
        },
        addAnswer() {
            this.question.answers.push({
                answer: null,
                score1: null,
                score2: null,
                score3: null,
            });
        },
        removeAnswer() {
            this.question.answers.pop();
        },
    },
};
</script>
