var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.params._product ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("상품 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("상품 상세")]), _c('v-tabs', {
    staticClass: "mt-4",
    attrs: {
      "background-color": "content"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    key: "basic"
  }, [_vm._v("기본상품정보")]), _c('v-tab-item', {
    key: "basic"
  }, [_c('product-basic-info', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }